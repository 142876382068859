<template>
  <v-dialog v-model="drawer" max-width="600">
    <v-card>
      <v-card-title class="my__v-card-title">
        <div>
          <div class="primary--text">{{ header }}</div>
          <div class="text-caption" style="margin-top: -6px;">{{ subHeader }}</div>
        </div>
        <v-icon @click="drawer=false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text  class="my__v-card-text">
        <div class="black">
          <v-carousel v-model="imgIndex" height="300" hide-delimiter-background delimiter-icon="mdi-minus">
            <v-carousel-item v-for="(image, index) in images" :key="index">
              <v-img :src="image" height="100%" contain>
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </div>
        <div class="text-center white--text black pb-2">
          description text
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ImageGallery',
  computed: {
    ...mapState('imageGallery', { header: 'header', subHeader: 'subHeader', images: 'images' }),
    drawer: {
      get () {
        return this.$store.state.imageGallery.drawer
      },
      set (to) {
        this.$store.commit('imageGallery/SET_DRAWER', to)
      }
    },
    imgIndex: {
      get () {
        return this.$store.state.imageGallery.imgIndex
      },
      set (to) {
        this.$store.commit('imageGallery/SET_IMAGE_INDEX', to)
      }
    }
  }
}
</script>

<style scoped>

</style>
