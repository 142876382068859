import axios from 'axios'
import api from '../../assets/js/api'
import utils from '../../assets/js/utils'

const maintenance = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getSettingPredictionDamage ({ commit, state, dispatch }) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/setting-prediction-damage`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getSettingSr ({ commit, state, dispatch }) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/setting-sr`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async setSettingPredictionDamage (
      { commit, state, dispatch }, payload
    ) {
      const formData = utils.payloadToFormData(payload)
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/set/setting-prediction-damage?test=test`
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async setSettingSr ({ commit, state, dispatch }, payload) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(payload)) {
        if (value !== null && value !== undefined) {
          params.append(key, value)
        }
      }
      const url = `${process.env.VUE_APP_WS_BASE}/set/setting-sr?${params.toString()}`
      const response = await api.post({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return false
      }
    }
  },
  getters: {}
}

export default maintenance
