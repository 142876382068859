import axios from 'axios'

const damageDetailSupply = {
  namespaced: true,
  state: {
    surveyTypeItem: {},
    bridgeViewArray: [
      {
        name_eng: 'sideView',
        name_th: 'ข้างสะพาน'
      },
      {
        name_eng: 'topView',
        name_th: 'บนสะพาน'
      },
      {
        name_eng: 'bottomView',
        name_th: 'ใต้สะพาน'
      }
    ]
  },
  getters: {
    surveyTypeArray: (state) => {
      return Object.entries(state.surveyTypeItem).map((entries, index) => ({ id: entries[0], name: entries[1].name }))
    },
    surveyTypeName: (state) => (id) => {
      if (!id || !state.surveyTypeItem[id]) return ''
      return state.surveyTypeItem[id].name
    }
  },
  actions: {
    async fetchSurveyItem ({ state, dispatch, commit }, payload) {
      if (Object.keys(state.surveyTypeItem).length > 0) {
        return state.surveyTypeItem
      }
      const url = `${process.env.VUE_APP_WS_BASE}/get/ref/inspection`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        commit('SET_REF_INSPECTION', data.data)
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    }
  },
  mutations: {
    SET_REF_INSPECTION (state, value) {
      const ref = {}
      value.forEach(typeObject => {
        const id = typeObject.id
        const name = typeObject.name
        let color = '#000000'
        let icon = 'mdi-pencil'
        if (name.includes('ปกติ')) {
          color = '#00ADD2'
          icon = 'mdi-clipboard-clock-outline'
        } else if (name.includes('พิเศษ')) {
          color = '#FCA034'
          icon = 'mdi-file-multiple-outline'
        } else if (name.includes('ฉุกเฉิน')) {
          color = '#E75858'
          icon = 'mdi-file-multiple-outline'
        } else if (name.includes('หลัก')) {
          color = '#6BAD66'
          icon = 'mdi-file-document-outline'
        } else {
          console.warn('could not recognize', typeObject)
        }
        ref[id] = { color, name, icon }
      })
      state.surveyTypeItem = ref
    }
  }
}

export default damageDetailSupply
