const utils = {
  /**
   * Convert payload Object into FormData and ready to send with multipart/form-data
   * @param {Object} payload
   * @returns {FormData}
   */
  payloadToFormData (payload) {
    const formData = new FormData()
    Object.keys(payload).forEach((key) => {
      const data = payload[key]
      if (Array.isArray(data)) {
        data.forEach((item) => {
          formData.append(`${key}[]`, item)
        })
      } else if (payload[key]) {
        formData.append(key, payload[key])
      } else if (key === 'pointReference' && String(data) === '0') {
        formData.append(key, payload[key])
      }
    })
    return formData
  },
  /**
   * await this function to sleep for {ms} time
   * @param ms
   * @returns {Promise}
   */
  sleep (ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  },
  /**
   * @param km
   * @returns {string}
   */
  getKm (km) {
    const numKm = Math.floor(km / 1000).toString()
    return `${numKm}+${km.toString().substring(numKm.length).padStart(3, '0')}`
  },
  validFileType: ['jpeg', 'jpg', 'png', 'JPEG', 'JPG', 'PNG'],
  /**
   * @param {Element} element
   * @returns {boolean}
   */
  isOverflown (element) {
    const { clientWidth, clientHeight, scrollWidth, scrollHeight } = element
    return scrollHeight > clientHeight || scrollWidth > clientWidth
  },
  /**
   * @param {String} pointString
   * @returns {{lon: String|Number, lat: String|Number}}
   */
  getLocationFromPointString (pointString) {
    const geom = pointString.replace('POINT', '').replace('(', '').replace(')', '')
    const lonlat = geom.split(' ')
    return { lon: lonlat[0], lat: lonlat[1] }
  },
  /**
   * @param {String} dateString - like 2009-10-16 21:30:45
   * @returns {Date}
   */
  stringToDate (dateString) {
    try {
      dateString = dateString.replace(/-/g, '/')
      return new Date(dateString)
    } catch (e) {
      console.warn(e)
      console.warn('Can not convert string to date, return this day instead')
      return new Date()
    }
  },
  /**
   * @param {Date} dateObj
   * @returns {string}
   */
  dateToThai (dateObj) {
    return dateObj.toLocaleString('th-TH', { dateStyle: 'medium' })
  },
  /**
   * @params {String} path
   * @returns {String}
   */
  realAttachmentsPath (path) {
    return process.env.VUE_APP_WS_BASE + `/sites/default/files/${path}`
  },
  // https://stackoverflow.com/a/365853
  degreesToRadians (degrees) {
    return degrees * Math.PI / 180
  },
  // https://stackoverflow.com/a/365853
  distanceInKmBetweenEarthCoordinates (lat1, lon1, lat2, lon2) {
    const earthRadiusKm = 6371

    const dLat = this.degreesToRadians(lat2 - lat1)
    const dLon = this.degreesToRadians(lon2 - lon1)

    lat1 = this.degreesToRadians(lat1)
    lat2 = this.degreesToRadians(lat2)

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    return earthRadiusKm * c
  },
  validateEmail (v) {
    return !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
  }
}

export default utils
