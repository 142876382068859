import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#C22D47', // in use
        'primary-light': '#FFF1F1', // in use
        secondary: '#27AE60',
        success: '#73BF12', // in use
        warning: '#FFC700', // in use
        error: '#BF1C12', // in use
        gold: '#A08B4F',
        'my-heavy-blue': '#5a9ad4', // in use
        'my-light-blue': '#dbe9f6', // in use

        // new color design
        aqua: '#2DC2A8',
        green: '#92C22D',
        blue: '#3E99C1',
        violet: '#5E2DC2',
        yellow: '#FBCF31',
        'theme-bg': '#F7F7F8'
      }
    }
  },
  icons: {
    values: {
      BridgeComponentIcon: {
        component: () =>
          import(
            /* webpackChunkName: "BridgeComponentIcon" */ '../components/icon/BridgeComponentIcon.vue'
          )
      },
      MainIcon: {
        component: () =>
          import(
            /* webpackChunkName: "MainIcon" */ '../components/icon/MainIcon.vue'
          )
      },
      BridgeIcon: {
        component: () =>
          import(
            /* webpackChunkName: "BridgeIcon" */ '../components/icon/BridgeIcon.vue'
          )
      },
      DamageIcon: {
        component: () =>
          import(
            /* webpackChunkName: "DamageIcon" */ '../components/icon/DamageIcon.vue'
          )
      },
      BudgetIcon: {
        component: () =>
          import(
            /* webpackChunkName: "BudgetIcon" */ '../components/icon/BudgetIcon.vue'
          )
      },
      MaintenanceIcon: {
        component: () =>
          import(
            /* webpackChunkName: "MaintenanceIcon" */ '../components/icon/MaintenanceIcon.vue'
          )
      },
      ReportIcon: {
        component: () =>
          import(
            /* webpackChunkName: "ReportIcon" */ '../components/icon/ReportIcon.vue'
          )
      },
      ChatIcon: {
        component: () =>
          import(
            /* webpackChunkName: "ChatIcon" */ '../components/icon/ChatIcon.vue'
          )
      },
      SettingIcon: {
        component: () =>
          import(
            /* webpackChunkName: "SettingIcon" */ '../components/icon/SettingIcon.vue'
          )
      },
      NormalBridgeIcon: {
        component: () =>
          import(
            /* webpackChunkName: "NormalBridgeIcon" */ '../components/icon/NormalBridgeIcon.vue'
          )
      },
      CrossBridgeIcon: {
        component: () =>
          import(
            /* webpackChunkName: "CrossBridgeIcon" */ '../components/icon/CrossBridgeIcon.vue'
          )
      },
      InterChangeBridgeIcon: {
        component: () =>
          import(
            /* webpackChunkName: "InterChangeBridgeIcon" */ '../components/icon/InterChangeBridgeIcon.vue'
          )
      },
      OverpassBridgeIcon: {
        component: () =>
          import(
            /* webpackChunkName: "InterChangeBridgeIcon" */ '../components/icon/OverpassBridgeIcon.vue'
          )
      }
    }
  }
})
