const layout = {
  namespaced: true,
  state: {
    drawer: false
  },
  mutations: {
    SET_DRAWER (state, value) {
      state.drawer = value
    }
  }
}

export default layout
