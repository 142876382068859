import axios from 'axios'
import ref from '@/store/modules/ref'

const bridgeDetail = {
  namespaced: true,
  state: {
    detail: {
      id: '',
      info: {}
    },
    inspectionLoading: false,
    inspectionListForBridgeId: '',
    /** @type inspectionListTable */
    inspectionList: [],
    /** @type BridgeOverview */
    bridgeOverview: {},
    isloading: false
  },
  getters: {
    bridgeHasA1: (state) => {
      return state.bridgeOverview.has_a1
    },
    bridgeHasA2: (state) => {
      return state.bridgeOverview.has_a2
    },
    // backend knows user from uuid in payload, different depot can not manage this bridge
    canAdd_permission: (state) => (!!state.bridgeOverview?.permissions?.can_add || false),
    canEdit_permission: (state) => (!!state.bridgeOverview?.permissions?.can_edit || false),
    canDelete_permission: (state) => (!!state.bridgeOverview?.permissions?.can_delete || false)
  },
  actions: {
    resetState ({ state, commit, dispatch }) {
      commit('SET_DETAIL', {
        id: '',
        info: {}
      })
    },
    async getBridgeDetail ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/bridge-info/${payload.id}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        commit('SET_DETAIL', data.data)
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getInspectionList ({ state, commit, dispatch }, payload) {
      if (
        !payload.force &&
        String(payload.id).trim() ===
          String(state.inspectionListForBridgeId).trim()
      ) {
        return state.inspectionList
      } else {
        commit('SET_INSPECTION_LIST', { data: [], id: payload.id })
      }
      const url = `${process.env.VUE_APP_WS_BASE}/get/inspection-list/${payload.id}`
      state.inspectionLoading = true
      const response = await axios({ url: url })
      state.inspectionLoading = false
      const data = response.data
      if (data.message === 'OK') {
        commit('SET_INSPECTION_LIST', { data: data.data, id: payload.id })
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async checkIsAllowed ({ state, commit, dispatch }, { componentId, inspectionType }) {
      // 1 = routine
      // 2 = pricipal
      // 3 = indepth
      // 4 = emergency
      await dispatch('ref/getRef', { method: 'bridge-component' })
      const componentList = ref.state.ref['bridge-component']
      const component = componentList.find(com => com.id === componentId)
      if (inspectionType === 2) {
        return component.is_allowed_principal
      } else if (inspectionType === 3) {
        return component.is_allowed_indepth
      } else {
        return true
      }
    }
  },
  mutations: {
    SET_DETAIL (state, value) {
      state.detail = value
    },
    SET_INSPECTION_LIST (state, value) {
      state.inspectionList = value.data
      state.inspectionListForBridgeId = value.id
    },
    SET_OVERVIEW (state, value) {
      state.bridgeOverview = value
    }
  },
  modules: {
    ref
  }
}

export default bridgeDetail
