const appbarText = {
  namespaced: true,
  state: {
    header: '',
    subHeader: '',
    defaultPreviousRoute: 'Home',
    fromRoute: ''
  },
  actions: {
    resetState ({ state, commit, dispatch }) {
      commit('SET_HEADER', '')
      commit('SET_SUB_HEADER', '')
    }
  },
  mutations: {
    SET_HEADER (state, value) {
      state.header = value
    },
    SET_SUB_HEADER (state, value) {
      state.subHeader = value
    },
    SET_ROUTE (state, value) {
      state.fromRoute = value
    },
    SET_DEFAULT_ROUTE (state, value) {
      state.defaultPreviousRoute = value
    }
  }
}

export default appbarText
