import axios from 'axios'

const bridgeComponent = {
  namespaced: true,
  state: {
    amphoe: [],
    province: [],
    nbes: [],
    ref: {},
    bridgeComponentMaterial: []
  },
  mutations: {
    SET_SELECTION (state, value) {
      state[value.ref] = value.selection
    },
    SET_REF (state, value) {
      state.ref[value.ref] = value.selection
    }
  },
  actions: {
    async getRef ({ commit, state, dispatch }, { method, filter }) {
      if (state.ref[method] && !filter) {
        return state.ref[method]
      } else {
        const params = new URLSearchParams()
        for (const [key, value] of Object.entries(filter || {})) {
          if (value) params.append(key, value)
        }
        const url = `${process.env.VUE_APP_WS_BASE}/get/ref/${method}${params.toString() ? `?${params.toString()}` : ''}`
        const response = await axios({ url: url })
        const data = response.data
        if (data.message === 'OK') {
          commit('SET_REF', { ref: method, selection: data.data })
          return data.data
        } else {
          console.warn(data)
          return []
        }
      }
    },
    async bridgeComponentMaterial ({ commit, state, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/ref/bridge-component-material`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        state.bridgeComponentMaterial = data.data
        return data.data
      } else {
        console.warn(data)
        return []
      }
    }
  },
  getters: {}
}

export default bridgeComponent
