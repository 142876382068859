import axios from 'axios'
import api from '../../assets/js/api'

const maintenance = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getMethodBridgeRepairList (
      { commit, state, dispatch },
      { filter }
    ) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(filter)) {
        if (key === 'ref_repair_ids') {
          value.forEach((repair) => {
            params.append('ref_repair_ids[]', repair)
          })
        } else if (key === 'ref_bridge_component_ids') {
          value.forEach((component) => {
            params.append('ref_bridge_component_ids[]', component)
          })
        } else if (value !== null && value !== undefined) {
          params.append(key, value)
        }
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/get/method-bridge-repair-list?${params.toString()}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getMethodBridgeRepair ({ commit, state, dispatch }, { id }) {
      //   const params = new URLSearchParams()
      //   for (const [key, value] of Object.entries(filter)) {
      //     if (value) params.append(key, value)
      //   }
      const url = `${process.env.VUE_APP_WS_BASE}/get/method-bridge-repair/${id}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getCalculateMethodRepair (
      { commit, state, dispatch },
      { bridgeRepairDetailId, refRepairId, dateCalculated }
    ) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/calculate-method-repair/${bridgeRepairDetailId}/${refRepairId}${
        dateCalculated ? `?date_calculated=${dateCalculated}` : ''
      }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async setMethodBridgeRepair (
      { commit, state, dispatch },
      { bridgeRepairId, payload }
    ) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(payload)) {
        if (value !== null && value !== undefined) {
          params.append(key, value)
        }
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/set/method-bridge-repair/${bridgeRepairId}?${params.toString()}`
      const response = await api.post({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async setMethodBridgeRepairDetail (
      { commit, state, dispatch },
      { bridgeRepairDetailId, refRepairId, optionCodes }
    ) {
      const params = new URLSearchParams()
      for (const option of optionCodes) {
        params.append('option_codes[]', option)
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/set/method-bridge-repair-detail/${bridgeRepairDetailId}/${refRepairId}?${params.toString()}`
      const response = await api.post({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },

    async setMethodBridgeRepairDone (
      { commit, state, dispatch },
      { bridgeRepairId }
    ) {
      const url = `${process.env.VUE_APP_WS_BASE}/set/method-bridge-repair-done/${bridgeRepairId}`
      const response = await api.post({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getMethodRepairExtraOption (
      { commit, state, dispatch },
      { bridgeRepairId }
    ) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/method-bridge-repair-extra-option/${
        bridgeRepairId
      }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async setMethodRepairExtraOption ({ commit, state, dispatch }, { bridgeRepairId, values }) {
      const params = new URLSearchParams()
      for (var i = 0; i < values.length; i++) {
        var object = values[i]
        for (var property in object) {
          params.append(`values[${i}][${property}]`, object[property])
        }
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/set/method-bridge-repair-extra-option/${bridgeRepairId}?${params.toString()}`
      const response = await api.post({ url: url })
      const data = response.data
      return data.message === 'OK'
    }
  },
  getters: {}
}

export default maintenance
