<template>
  <v-dialog v-model="drawer"
            :max-width="(showSuccess || showFail) ? 250 : 600"
            :persistent="showSuccess || showFail">
    <v-card>
      <v-card-title v-if="!showSuccess && !showFail"
                    class="my__v-card-title">
        <div>
          <div class="primary--text">
            {{ header }}
          </div>
          <div class="text-caption"
               style="margin-top: -6px;">
            {{ subHeader }}
          </div>
        </div>
        <v-icon @click="drawer=false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-divider/>
      <v-card-text class="my__v-card-text">
        <div v-if="!showSuccess && !showFail"
             class="ma-4"
             style="position: relative;">
          <input
            v-if="!imageUrl"
            class="d-none"
            ref="cameraOpener"
            type="file"
            accept="image/*;capture=camera"
            @change="onFileChange">
          <div v-if="!imageUrl && can_add_inspection"
               ref="dropArea"
               class="uploadBox d-flex align-center"
               style="height: 300px;"
               @dragover.prevent="dragOver"
               @dragleave="dragLeave"
               @drop.prevent="drop">
            <div style="width: 100%; height: fit-content;">
              <div class="mb-3"  style="width: 100%; text-align: center;">
                <v-icon style="font-size: 6rem; color:#A6A5A5;">
                  mdi-camera-plus-outline
                </v-icon>
              </div>
              <div v-if="validImage"
                   class="text-center"
                   style="color:#A6A5A5; width: 100%;">
                คลิกหรือลากไฟล์เพื่ออัปโหลดรูปภาพ <br/>
                (ระบบรองรับเฉพาะไฟล์  JPG และ PNG เท่านั้น)
              </div>
              <div v-else
                   class="error--text text-center"
                   style="width: 100%;">
                ไฟล์ภาพไม่ถูกต้อง กรุณาเลือกภาพใหม่
              </div>
              <div class="mt-3"  style="width: 100%; text-align: center;">
                <v-btn color="blue white--text" elevation="0"
                       @click="$refs.cameraOpener.click()">
                  อัปโหลด
                </v-btn>
              </div>
            </div>
          </div>
          <v-img v-else-if="imageUrl"
                 :src="imageUrl"
                 contain
                 height="400">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0"
                     align="center"
                     justify="center">
                <v-progress-circular indeterminate/>
              </v-row>
            </template>
          </v-img>
          <div v-else-if="!can_add_inspection" class="text-center pa-4">
            ไม่มีรูปภาพ
          </div>
          <div v-if="imageUrl"
               class="d-flex align-end justify-center">
            {{ caption }}
          </div>
          <div v-if="imageUrl && can_add_inspection"
               class="px-1 pointer-hover"
               style="position: absolute; top: 16px; right: 16px; background: rgba(0, 0, 0, 0.25); border-radius: 5px;"
               @click="imageObject=null; validImage=true">
            <v-icon small
                    color="white">
              mdi-close
            </v-icon>
          </div>
        </div>
        <div v-else-if="showSuccess"
             class="pa-4 pb-1 text-center">
          <v-icon class="green--text" style="font-size: 57px;">
            mdi-check-circle-outline
          </v-icon>
          <div class="black--text font-weight-bold my-4" style="font-size: 1.5rem;">
            บันทึกสำเร็จ
          </div>
          <div class="progress-green">
            <div class="color-green"></div>
          </div>
        </div>
        <div v-else-if="showFail"
             class="pa-4 pb-1 text-center">
          <v-icon class="red--text" style="font-size: 57px;">
            mdi-check-circle-outline
          </v-icon>
          <div class="black--text font-weight-bold my-4 mb-5" style="font-size: 1.5rem;">
            บันทึกไม่สำเร็จ
          </div>
          <div class="progress-red">
            <div class="color-red"></div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions v-if="!showSuccess && !showFail"
                      class="justify-space-between pt-0">
        <div>
          <v-btn v-if="imageUrl && showRemove && can_add_inspection" outlined color="primary" @click="warningDrawer=true">
            ลบ
          </v-btn>
        </div>
        <div>
          <v-btn v-if="can_add_inspection" :disabled="!imageUrl || !imageObject.name" color="primary white--text" elevation="0" class="px-4" @click="saveImage">
            บันทึก
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="warningDrawer" max-width="250">
      <v-card>
        <v-card-text class="my__v-card-text">
          <div class="pa-4 text-center">
            <v-icon class="warning--text" style="font-size: 57px;">
              mdi-alert-outline
            </v-icon>
            <div class="black--text font-weight-bold my-4 mb-5" style="font-size: 1.5rem;">
              ยืนยันการลบรูปภาพ
            </div>
            <div class="d-flex justify-space-between" style="width: 100%;">
              <v-btn outlined color="grey" style="height: 32px;" @click="warningDrawer=false">
                ยกเลิก
              </v-btn>
              <v-btn color="blue white--text" elevation="0" style="height: 32px;" @click="removeImage">
                ยืนยัน
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import utils from '@/assets/js/utils'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ImageUploader',
  data: () => ({
    validImage: true,
    showSuccess: false,
    showFail: false,
    warningDrawer: false
  }),
  methods: {
    dragOver (event) {
      const el = event.currentTarget
      if (!el.classList.contains('uploadBox-drag')) {
        el.classList.remove('uploadBox-error')
        el.classList.add('uploadBox-drag')
      }
    },
    dragLeave (event) {
      const el = event.currentTarget
      el.classList.remove('uploadBox-error')
      el.classList.remove('uploadBox-drag')
    },
    drop (event) {
      const el = event.currentTarget
      this.dragLeave(event)
      const files = event.dataTransfer.files
      if (files.length > 1) {
        this.validImage = false
        el.classList.add('uploadBox-error')
      } else {
        this.onFileChange({ target: { files } })
      }
    },
    onFileChange (e) {
      if (e.target.files.length <= 0) return false
      const file = e.target.files[0]
      const fileName = file.name
      const extension = fileName.split('.').pop()
      const validFileType = utils.validFileType.includes(extension)
      if (!validFileType) {
        this.validImage = false
        this.$refs.dropArea.classList.add('uploadBox-error')
      } else {
        this.imageObject = file
      }
    },
    async saveImage () {
      this.$store.commit('imageUploader/SET_FILE', this.imageObject)
      const result = await this.$store.dispatch('imageUploader/save', {})
      if (this.showResponseDialog) {
        this.showSuccess = !!result
        this.showFail = !result
        setTimeout(() => {
          this.drawer = false
        }, 3000)
      } else {
        this.drawer = false
      }
    },
    async removeImage () {
      const result = await this.$store.dispatch('imageUploader/remove', {})
      this.warningDrawer = false
      if (this.showResponseDialog) {
        this.showSuccess = !!result
        this.showFail = !result
        setTimeout(() => {
          this.drawer = false
        }, 3000)
      }
    }
  },
  computed: {
    ...mapState('imageUploader', { header: 'header', subHeader: 'subHeader', onOpen: 'onOpen', showRemove: 'showRemove', caption: 'caption', showResponseDialog: 'showResponseDialog' }),
    ...mapGetters('bridgeDetail', { canAdd_permission: 'canAdd_permission', canEdit_permission: 'canEdit_permission', canDelete_permission: 'canDelete_permission' }),
    ...mapGetters({ user_can_add_inspection: 'can_add_inspection' }),
    can_add_inspection () {
      return this.user_can_add_inspection && this.canAdd_permission && this.canEdit_permission && this.canDelete_permission
    },
    drawer: {
      get () {
        return this.$store.state.imageUploader.drawer
      },
      set (to) {
        this.$store.commit('imageUploader/SET_DRAWER', to)
      }
    },
    imageObject: {
      get () {
        return this.$store.state.imageUploader.imageObject
      },
      set (to) {
        this.$store.commit('imageUploader/SET_FILE', to)
      }
    },
    imageUrl () {
      if (this.imageObject && this.imageObject.name) {
        return URL.createObjectURL(this.imageObject)
      } else if (this.imageObject) {
        return this.imageObject
      } else {
        return ''
      }
    }
  },
  watch: {
    drawer (to, from) {
      if (this.$refs.dropArea) {
        this.$refs.dropArea.classList.remove('uploadBox-error')
        this.$refs.dropArea.classList.remove('uploadBox-drag')
      }
      if (to && !from) {
        if (this.onOpen.includes('clickInput')) {
          this.$nextTick(() => {
            this.$refs.cameraOpener.click()
          })
        }
      }
      setTimeout(() => {
        this.validImage = true
        this.showSuccess = false
        this.showFail = false
      }, 500)
    }
  }
}
</script>

<style scoped>
.uploadBox {
  background: #F9F9F9;
  border: 2px dashed #B4B4B4;
  border-radius: 5px;
}

.uploadBox-drag {
  border: 4px dashed #BEDF76 !important;
}

.uploadBox-error {
  border: 4px dashed #bf1c12 !important;
}
</style>
