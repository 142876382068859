import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import api from '../assets/js/api'
import user from '../store/modules/user'
import layout from '../store/modules/layout'
import appbarText from '../store/modules/appbarText'
import bridgeDetail from '../store/modules/bridgeDetail'
import damageDetailSupply from '../store/modules/damageDetailSupply'
import imageGallery from '../store/modules/imageGallery'
import inspectionDamageDialog from '../store/modules/inspectionDamageDialog'
import imageUploader from '@/store/modules/imageUploader'
import ref from '@/store/modules/ref'
import maintenance from '@/store/modules/maintenance'
import repair from '@/store/modules/repair'
import budget from '@/store/modules/budget'
import setting from '@/store/modules/setting'
import utils from '@/assets/js/utils'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    refDamageGroup: [],
    refDamageIndepthGroup: [],
    refScorePrincipal: [],
    refScoreIndepth: [],
    refBridgeComponent: [],
    alertMessage: false,
    isBridgeOverviewLoading: false,
    bridgeComponentIndepthGroup: [],
    pointCloud: {}
  },
  getters: {
    getUnitFromComponentId: (state) => (componentId) => {
      const refComponent = state.refBridgeComponent.find(
        (component) => component.id === componentId
      )
      const unit = refComponent?.ref_unit_id || null
      switch (String(unit)) {
        case '1':
          return 'ตร.ม.'
        case '2':
          return 'ม.'
        case '3':
          return 'ชิ้น'
        case '4':
          return 'ต้น'
        default:
          return 'Unrecognized component_id'
      }
    },
    administer_m_bmms_comfiguration: (state) =>
      state.user &&
      state.user.permissions &&
      state.user.permissions.length &&
      state.user.permissions.includes('administer m_bmms configuration'),
    edit_budget: (state) =>
      state.user &&
      state.user.permissions &&
      state.user.permissions.length &&
      state.user.permissions.includes('edit budget'),
    manage_settings: (state) =>
      state.user &&
      state.user.permissions &&
      state.user.permissions.length &&
      state.user.permissions.includes('manage settings'),
    approve_inspection: (state) =>
      state.user &&
      state.user.permissions &&
      state.user.permissions.length &&
      state.user.permissions.includes('approve inspection'),
    manage_inspection: (state) =>
      state.user &&
      state.user.permissions &&
      state.user.permissions.length &&
      state.user.permissions.includes('manage inspection'),
    edit_inventory: (state) =>
      state.user &&
      state.user.permissions &&
      state.user.permissions.length &&
      state.user.permissions.includes('edit inventory'),
    manage_inventory: (state) =>
      state.user &&
      state.user.permissions &&
      state.user.permissions.length &&
      state.user.permissions.includes('manage inventory'),
    manage_maintenance: (state) =>
      state.user &&
      state.user.permissions &&
      state.user.permissions.length &&
      state.user.permissions.includes('manage maintenance'),
    access_budget: (state) =>
      state.user &&
      state.user.permissions &&
      state.user.permissions.length &&
      state.user.permissions.includes('access budget'),
    access_inspection: (state) =>
      state.user &&
      state.user.permissions &&
      state.user.permissions.length &&
      state.user.permissions.includes('access inspection'),
    access_inventory: (state) =>
      state.user &&
      state.user.permissions &&
      state.user.permissions.length &&
      state.user.permissions.includes('access inventory'),
    access_history: (state) =>
      state.user &&
      state.user.permissions &&
      state.user.permissions.length &&
      state.user.permissions.includes('access history'),
    access_repair: (state) =>
      state.user &&
      state.user.permissions &&
      state.user.permissions.length &&
      state.user.permissions.includes('access repair'),
    access_forum: (state) =>
      state.user &&
      state.user.permissions &&
      state.user.permissions.length &&
      state.user.permissions.includes('access forum'),
    access_homepage: (state) =>
      state.user &&
      state.user.permissions &&
      state.user.permissions.length &&
      state.user.permissions.includes('access homepage'),
    access_report: (state) =>
      state.user &&
      state.user.permissions &&
      state.user.permissions.length &&
      state.user.permissions.includes('access report'),
    access_settings: (state) =>
      state.user &&
      state.user.permissions &&
      state.user.permissions.length &&
      state.user.permissions.includes('access settings'),
    edit_repair: (state) =>
      state.user &&
      state.user.permissions &&
      state.user.permissions.length &&
      state.user.permissions.includes('edit repair'),
    can_add_inspection: (state, getters) =>
      getters.manage_inspection || getters.approve_inspection,
    can_finish_inspection: (state, getters) => getters.approve_inspection,
    delete_bridge: (state, getters) =>
      state.user &&
      (state.user.roles.includes('administrator') ||
        state.user.roles.includes('administrator_front'))
  },
  mutations: {
    SET_ALERT_MESSAGE (state, value) {
      state.alertMessage = value
    }
  },
  actions: {
    async geolocationStatus ({ commit, state, dispatch }, payload) {
      let status = { state: 'denied' }
      try {
        status = await navigator.permissions.query({ name: 'geolocation' })
      } catch (e) {
        console.warn({ ...e, myLogger: 'navigator.permissions.query error' })
      }
      return status.state === 'granted'
    },
    async getBridgeList ({ state, commit, dispatch }, payload) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(payload)) {
        if (value !== null && value !== undefined) params.append(key, value)
      }
      const url = `${process.env.VUE_APP_WS_BASE}/get/bridge-list${
        params.toString() && '?' + params.toString()
      }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getMaterialByComponent ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/material-by-component`
      const formData = utils.payloadToFormData(payload)
      const response = await api.post({ url, data: formData })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getBridgeDamageList ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/bridge-list-by-location`
      const formData = utils.payloadToFormData(payload)
      const response = await api.post({ url, data: formData })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getBridgeAttribute ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/bridge-attr/${payload.id}?group=${payload.group}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getRefRoad ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/ref/road`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getSectionPart ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/ref/section_part/${payload.roadid}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getBridgeOverview ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/bridge-overview?bridgeCode=${payload.bridgeCode}`
      state.isBridgeOverviewLoading = true
      const response = await api.get({ url: url })
      state.isBridgeOverviewLoading = false
      const data = response.data
      if (data.message === 'OK') {
        commit('bridgeDetail/SET_OVERVIEW', data.data)
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getRoutineOverview ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/routine-overview/${payload.inspectionId}/${payload.bridgeCode}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getRoutineTop ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/routine-top/${payload.inspectionId}/${payload.bridgeCode}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getRoutineBottom ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/routine-bottom/${payload.inspectionId}/${payload.bridgeCode}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getBridgeSegment ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/bridge-segment/${payload.bridgeCode}?partCode=${payload.partCode}&seq=${payload.seq}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getBridgePier ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/bridge-pier/${payload.bridgeCode}?partCode=${payload.partCode}&seq=${payload.seq}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getIndeptSectorComplete ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/indepth-sector/${payload.inspectionId}/${payload.bridgeView}?partCode=${payload.partCode}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async setBridgeInfo ({ commit, state, dispatch }, { id, payload }) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(payload)) {
        if (key === 'nbesJson') {
          params.append(
            key,
            JSON.stringify(value, (kaynbes, valuenbes) => {
              if (valuenbes !== null) return valuenbes
            })
          )
        } else if (value !== null && value !== undefined) {
          params.append(key, value)
        }
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/set/bridge-info/${id}?${params.toString()}`
      const response = await api.post({ url: url })
      const data = response.data
      return data.message === 'OK'
    },

    async getRefDamageGroup ({ commit, state, dispatch }, payload) {
      if (state.refDamageGroup.length > 0) {
        return state.refDamageGroup
      }
      const url = `${process.env.VUE_APP_WS_BASE}/get/ref/damage-principal-group`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        state.refDamageGroup = data.data
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getRefDamageIndepthGroup ({ commit, state, dispatch }, payload) {
      if (state.refDamageIndepthGroup.length > 0) {
        return state.refDamageIndepthGroup
      }
      const url = `${process.env.VUE_APP_WS_BASE}/get/ref/damage-indepth-group`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        state.refDamageIndepthGroup = data.data
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getRefScorePrincipal ({ commit, state, dispatch }, payload) {
      if (state.refScorePrincipal.length > 0) {
        return state.refScorePrincipal
      }
      const url = `${process.env.VUE_APP_WS_BASE}/get/ref/score-principal`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        state.refScorePrincipal = data.data
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getRefScoreIndepth ({ commit, state, dispatch }, payload) {
      if (state.refScoreIndepth.length > 0) {
        return state.refScoreIndepth
      }
      const url = `${process.env.VUE_APP_WS_BASE}/get/ref/score-indepth`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        state.refScoreIndepth = data.data
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getBridgeGeneralDetail ({ commit, state, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/bridge-general-detail/${payload.bridgeId}?partCode=${payload.partCode}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async setBridgeSegment ({ commit, state, dispatch }, { id, seq, payload }) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(payload)) {
        if (value !== null && value !== undefined) {
          params.append(key, value)
        }
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/set/bridge-segment/${id}/${seq}?${params.toString()}`
      const response = await api.post({ url: url })
      const data = response.data
      return data.message === 'OK'
    },
    async setBridgePier ({ commit, state, dispatch }, { id, seq, payload }) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(payload)) {
        if (value !== null || value !== undefined) {
          params.append(key, value)
        }
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/set/bridge-pier/${id}/${seq}?${params.toString()}`
      const response = await api.post({ url: url })
      const data = response.data
      return data.message === 'OK'
    },
    async getBridgeRevisionList (
      { commit, state, dispatch },
      { bridgeId, groupCode }
    ) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/bridge-revision-list/${bridgeId}/${groupCode}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getBridgeRevisionInfo (
      { commit, state, dispatch },
      { bridgeId, groupCode, revisionId }
    ) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/bridge-revision-info/${bridgeId}/${groupCode}/${revisionId}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async delBridgeSegmentAndPier (
      { state, commit, dispatch, getters },
      { bridgePartId, seq }
    ) {
      const url = `${process.env.VUE_APP_WS_BASE}/del/bridge-segment-and-pier/${bridgePartId}/${seq}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        return false
      }
    },
    async getComponentIndepthGroup ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/ref/bridge-component-indepth-group`
      const response = await api.get({ url })
      const data = response.data
      if (data.message === 'OK') {
        state.bridgeComponentIndepthGroup = data.data
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getBridgeComponent ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/ref/bridge-component`
      const response = await api.get({ url })
      const data = response.data
      if (data.message === 'OK') {
        state.refBridgeComponent = data.data
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getPredictionDamage ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/prediction-damage/${payload.bridgeId}`
      const response = await api.get({ url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getPointCloudMetaData ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/point-cloud-meta-data/${payload.id}`
      const response = await api.get({ url })
      const data = response.data
      if (data.message === 'OK') {
        state.pointCloud = data.data || {}
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },

    async getCountPrincipalGroupBySr (
      { state, commit, dispatch },
      { budgetYear, filter }
    ) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(filter)) {
        if (value !== null && value !== undefined) params.append(key, value)
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/get/count-principal-group-by-sr/${budgetYear}?${params.toString()}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },

    async getBudgetYearList ({ state, commit, dispatch }) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/budget-year-list`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },

    async getCountSurveyPrincipal (
      { state, commit, dispatch },
      { budgetYear, filter }
    ) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(filter)) {
        if (value !== null && value !== undefined) params.append(key, value)
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/get/count-survey-principal/${budgetYear}?${params.toString()}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },

    async getCountPrincipalGroupByDepot (
      { state, commit, dispatch },
      { budgetYear, filter }
    ) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(filter)) {
        if (value !== null && value !== undefined) params.append(key, value)
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/get/count-principal-group-sr-by-depot/${budgetYear}?${params.toString()}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },

    async getBridgeListByPrincipalGroupBySrRange (
      { state, commit, dispatch },
      { budgetYear, filter }
    ) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(filter)) {
        if (value !== null && value !== undefined) params.append(key, value)
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/get/bridge-list-by-principal-group-by-sr-range/${budgetYear}?${params.toString()}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },

    async getBridgeListCountSurveyPrincipal (
      { state, commit, dispatch },
      { budgetYear, filter }
    ) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(filter)) {
        if (value !== null && value !== undefined) params.append(key, value)
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/get/bridge-list-count-survey-principal/${budgetYear}?${params.toString()}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },

    async getRefFilterSr ({ state, commit, dispatch }) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/ref-filter-sr`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },

    async setBridge ({ commit, state, dispatch }, payload) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(payload)) {
        if (value !== null || value !== undefined) {
          params.append(key, value)
        }
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/set/bridge?${params.toString()}`
      const response = await api.post({ url: url })
      const data = response.data
      return data.code === 200 ? data.data : false
    },
    async getCountBridgeByServiceBridgeUnder (
      { state, commit, dispatch },
      filter
    ) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(filter)) {
        if (value !== null && value !== undefined) params.append(key, value)
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/get/count-bridge-by-service-bridge-under?${params.toString()}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },

    async getCountSurveyRoutine (
      { state, commit, dispatch },
      { budgetYear, filter }
    ) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(filter)) {
        if (value !== null && value !== undefined) params.append(key, value)
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/get/count-survey-routine/${budgetYear}?${params.toString()}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getBridgeListCountSurveyRoutine (
      { state, commit, dispatch },
      { budgetYear, filter }
    ) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(filter)) {
        if (value !== null && value !== undefined) params.append(key, value)
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/get/bridge-list-count-survey-routine/${budgetYear}?${params.toString()}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async delBridge ({ state, commit, dispatch, getters }, { bridgeId }) {
      const url = `${process.env.VUE_APP_WS_BASE}/del/bridge/${bridgeId}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        return false
      }
    },
    async getDocumentDownloadList ({ state, commit, dispatch, getters }) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/document-download-list`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        return []
      }
    }
  },
  modules: {
    user,
    layout,
    appbarText,
    bridgeDetail,
    damageDetailSupply,
    imageGallery,
    inspectionDamageDialog,
    imageUploader,
    ref,
    maintenance,
    repair,
    budget,
    setting
  }
})
