import utils from '@/assets/js/utils'
import api from '@/assets/js/api'

const inspectionDamageDialog = {
  namespaced: true,
  state: {
    damageDetailTab: 0,
    stepZeroComponent: null,
    stepOneComponent: null,
    stepZeroScrollDistance: 0,
    surveyDrawer: false,
    surveyMode: '',
    bridgeView: '',
    drawer: false,
    bridgeId: '',
    inspectionId: '',
    inspectionType: null,
    inspectorName: '',
    inspectionDate: '',
    step: 0,
    finishStatus: false,
    loading: false,
    openHistoryDialog: false,
    openSuggestionDialog: false,
    /** @type {PrincipalList} */
    inspectionItemList: [],
    inspectionSummary: {},
    othersSummary: {},
    /** @type {RoutineItem} */
    routineOverviewItem: [],
    /** @type {RoutineItem} */
    routineTopItem: [],
    /** @type {RoutineItem} */
    routineBottomItem: [],
    svgStep: 0,
    bridgePartId: null, // id
    partsText: null,
    sectorId: null,
    sectorNo: null,
    otherParts: [],
    componentId: null,
    componentNo: null,
    elementNoWeightArray: [],
    elementNoWeight: null,
    damageArray: [],
    stepTwoFormSuccess: false,
    stepTwoDimensionSuccess: false,
    principalId: null,
    damageTypeId: null,
    scoreId: null,
    hasCritical: null, // Integer 0, 1
    areaCritical: null,
    damageWidth: null,
    damageLength: null,
    damageHeight: null,
    damageThickness: null,
    damagePiece: null,
    pointReference: null,
    remarks: null,
    pathImages: [],
    revision: null,
    forceSave: null, // Integer
    /** @type InspectionHistory */
    principalHistory: {},
    currentSeq: 0,
    currentImage: '',
    seqDetail: '',
    imageId: 0,
    /** @type {'overview' || 'top' || 'bottom' || ''} */
    routineMode: '',
    requiredMaterial: [],
    materialId: '',
    componentPoleId: '',
    has_width: false,
    has_length: false,
    has_height: false,
    has_thickness: false,
    has_piece: false,
    has_input_width: false,
    has_input_length: false,
    has_input_height: false,
    has_input_thickness: false,
    has_input_piece: true,
    dimension_width: '',
    dimension_length: '',
    dimension_height: '',
    dimension_thickness: '',
    dimension_piece: '',
    principalUnit: ''
  },
  getters: {
    bridgePartArray: (state, getters, rootState) => {
      return rootState.bridgeDetail.bridgeOverview.bridge_part.map((item, index) => ({
        name: item[0].partcode,
        bridgePartId: parseInt(item[0].bridge_part_id),
        ...item
      }))
    },
    /** @return {BridgePart} */
    getBridge: (state, getters) => {
      return getters.bridgePartArray.find(bridge => String(bridge.bridgePartId) === String(state.bridgePartId))
    },
    bridgePartOptions: (state, getters) => {
      return getters.bridgePartArray.map(subPart => ({ bridgePartId: subPart.bridgePartId, name: subPart.name }))
    },
    availableSector: (state) => {
      const options = state.inspectionItemList
        .map(({ sector_id: sectorId, items }) => items
          .map(({ sector_no: sectorNo }) => ({ sectorId, sectorNo, text: `${sectorId}${sectorNo}` })))
        .flat()
      options.unshift({ sectorId: '', sectorNo: '', text: 'ทั้งหมด' })
      return options
    },
    currentPier: (state, getters) => {
      return getters.getBridge.bridge_pier.find(pier => String(pier.seq) === String(state.sectorNo))
    },
    componentUnit: (state, getters, rootState) => {
      const refBridgeComponent = rootState.refBridgeComponent
      const bridgeComponentItem = refBridgeComponent.find((item) => item.id === state.componentId)
      return bridgeComponentItem?.ref_unit_id || null
    },
    requiredMaterialWithName: (state, getters, rootState) => {
      return rootState.ref.bridgeComponentMaterial.filter(material => state.requiredMaterial.includes(material.id))
    },
    apiName: (state) => {
      switch (String(state.inspectionType)) {
        case ('2'):
          return 'inspection-principal'
        case ('3') :
          return 'inspection-indepth'
        case ('4') :
          return 'inspection-emergency'
      }
    },
    apiNameList: (state) => {
      switch (String(state.inspectionType)) {
        case ('2'):
          return 'inspection-principal-list'
        case ('3') :
          return 'indepth-list'
        case ('4') :
          return 'emergency-list'
      }
    },
    apiSummaryName: (state) => {
      switch (String(state.inspectionType)) {
        case ('2'):
          return 'inspection-principal-summary'
        case ('3') :
          return 'indepth-list-by-group'
        case ('4') :
          return 'emergency-list-by-group'
      }
    },
    commitSummaryName: (state) => {
      switch (String(state.inspectionType)) {
        case ('2'):
          return 'SET_PRINCIPAL_SUMMARY'
        default:
          return 'SET_OTHERS_SUMMARY'
      }
    },
    apiNameHistory: (state) => {
      switch (String(state.inspectionType)) {
        case ('2'):
          return 'inspection-principal-history'
        case ('3') :
          return 'inspection-indepth-history'
        case ('4') :
          return 'inspection-emergency-history'
      }
    },
    routineImgApi: (state) => {
      switch (state.routineMode) {
        case ('overview'):
          return 'routine-overview-img'
        case ('top') :
          return 'routine-top-img'
        case ('bottom') :
          return 'routine-bottom-img'
      }
    },
    bridgePositionId: (state) => {
      switch (state.bridgeView) {
        case ('bottomView'):
          return 2
        case ('crossSectionView'):
          return 4
        case ('topView'):
          return 1
        case ('sideView'):
          return 3
        default:
          return ''
      }
    }
  },
  actions: {
    // click row in /damageDetail?tab=1
    async setInspectionDialog ({ state, commit, dispatch, getters }, payload) {
      commit('SET_DRAWER', true)
      commit('SVG_STEP', 0)
      commit('SET_INSPECTION', payload)
      // --
      await dispatch('refreshInspection')
    },
    async overallInspectionData ({ state }) {
      const inspectionId = state.inspectionId
      const url = `${process.env.VUE_APP_WS_BASE}/get/inspection/${inspectionId}`
      const response = await api.get({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        const finishStatus = data.data.status
        state.finishStatus = String(finishStatus.id) === '2'
      }
    },
    async refreshInspection ({ state, commit, dispatch, getters }, payload) {
      commit('SET_LOADING', true)
      switch (String(state.inspectionType)) {
        case ('1'):
          await dispatch('fetchRoutineDialog')
          break
        default:
          // eslint-disable-next-line no-case-declarations
          const p = dispatch('overallInspectionData', payload)
          // eslint-disable-next-line no-case-declarations
          const dialogPromise = dispatch('fetchInspectionDialog', payload)
          if (!payload) {
            await dispatch('fetchInspectionSummary')
          }
          await dialogPromise
          await p
          break
      }
      commit('SET_LOADING', false)
      return 0
    },
    // [setDamageDialog] children
    async fetchInspectionDialog ({ state, commit, dispatch, getters }, payload) {
      commit('SET_PRINCIPAL_ITEM_LIST', [])
      let url = `${process.env.VUE_APP_WS_BASE}/get/${getters.apiNameList}/${state.inspectionId}`
      if (payload && payload.sectorId) {
        url += `?sectorId=${payload.sectorId}&sectorNo=${payload.sectorNo}`
      }
      const response = await api.get({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        commit('SET_PRINCIPAL_ITEM_LIST', data.data || [])
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async fetchInspectionSummary ({ state, commit, dispatch, getters }, payload) {
      commit('SET_PRINCIPAL_SUMMARY', { condition_rating: [], appraisal_rating: [] })
      const url = `${process.env.VUE_APP_WS_BASE}/get/${getters.apiSummaryName}/${state.inspectionId}`
      const response = await api.get({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        commit(getters.commitSummaryName, data.data)
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async setInspectionDone ({ state, commit, dispatch, getters }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/set/inspection-done/${state.inspectionId}`
      const formData = utils.payloadToFormData({ bridge_part_id: state.bridgePartId })
      const response = await api.post({ url, data: formData })
      const data = response.data
      return data.message === 'OK'
    },
    async fetchRoutineDialog ({ state, commit, dispatch, getters }, payload) {
      commit('SET_LOADING', true)
      const targets = [
        { apiName: 'routine-overview', commitName: 'SET_ROUTINE_OVERVIEW_ITEM' },
        { apiName: 'routine-top', commitName: 'SET_ROUTINE_TOP_ITEM' },
        { apiName: 'routine-bottom', commitName: 'SET_ROUTINE_BOTTOM_ITEM' }
      ]
      // if no default bridgePartId -> use L2/R2
      if (!state.bridgePartId) {
        if (getters.bridgePartArray[1]) {
          state.bridgePartId = getters.bridgePartArray[1].bridgePartId
        } else {
          state.bridgePartId = getters.bridgePartArray[0].bridgePartId
        }
      }
      const bridgePartId = state.bridgePartId
      const promises = targets.map(async (target) => {
        commit(target.commitName, [])
        // before is getters.bridgePartArray[0].bridgePartId
        const url = `${process.env.VUE_APP_WS_BASE}/get/${target.apiName}/${state.inspectionId}/${bridgePartId}`
        const response = await api.get({ url: url })
        const data = response.data
        if (data.message === 'OK') {
          commit(target.commitName, data.data || [])
          return data.data || []
        } else {
          console.warn(data)
          return {}
        }
      })
      await Promise.allSettled(promises)
      commit('SET_LOADING', false)
    },
    async removeRoutineImage ({ state, commit, dispatch, getters }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/del/${getters.routineImgApi}/${state.inspectionId}/${state.bridgePartId}/${state.imageId}`
      const response = await api.get({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        dispatch('fetchRoutineDialog')
        return true
      } else {
        return false
      }
    },
    async setRoutineImage ({ state, commit, dispatch, getters }, payload) {
      // If no image present, bridge svg return -1
      const imageId = Math.max(parseInt(state.imageId), 0)
      const url = `${process.env.VUE_APP_WS_BASE}/set/${getters.routineImgApi}/${state.inspectionId}/${state.bridgePartId}/${imageId}`
      const formData = utils.payloadToFormData({ seq: state.currentSeq, image: state.currentImage })
      const response = await api.post({ url, data: formData })
      const data = response.data
      if (data.message === 'OK') {
        dispatch('fetchRoutineDialog')
        return true
      } else {
        return false
      }
    },
    // click plus in /damageDetail
    openNewInspection ({ state, commit, dispatch, getters }, payload) {
      commit('SET_SURVEY_MODE', 'add')
      commit('SET_INSPECTION_ID', 0)
      commit('SET_SURVEY_DRAWER', true)
    },
    // click edit from dialog
    openEditInspection ({ state, commit, dispatch, getters }, payload) {
      commit('SET_SURVEY_MODE', 'edit')
      commit('SET_SURVEY_DRAWER', true)
    },
    // click confirm in inspection form dialog
    async confirmInspectionForm ({ state, commit, dispatch, getters }, payload) {
      commit('SET_INSPECTION_FORM', payload)
      const inspectionId = await dispatch('postInspectionForm', payload)
      const dispatchPayload = {
        id: inspectionId,
        type: { id: payload.inspectionType },
        bridgeId: state.bridgeId
      }
      dispatch('setInspectionDialog', dispatchPayload)
    },
    // [confirmInspection] children
    async postInspectionForm ({ state, commit, dispatch, getters }, _payload) {
      const payload = { inspectorName: state.inspectorName, inspectionDate: state.inspectionDate, inspectionTypeId: state.inspectionType }
      let url
      if (_payload.duplicateInspection) {
        url = `${process.env.VUE_APP_WS_BASE}/set/duplicate-inspection/${_payload.fromInspectionId}`
      } else {
        url = `${process.env.VUE_APP_WS_BASE}/set/inspection/${state.bridgeId}/${state.inspectionId}`
      }
      const formData = utils.payloadToFormData(payload)
      const response = await api.post({ url, data: formData })
      const data = response.data
      if (data.message === 'OK') {
        const newInspectionId =
          (_payload.duplicateInspection)
            ? data.data.new_bridge_inspection_id
            : (typeof data.data === 'number')
              ? data.data
              : state.inspectionId
        commit('SET_INSPECTION_ID', newInspectionId)
        return newInspectionId
      } else {
        console.warn(data)
        return 0
      }
    },
    // [AddSurveyDialog.vue] children
    async fetchInspectionForm ({ state, commit, dispatch, getters }, _payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/inspection/${state.inspectionId}`
      const response = await api.get({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        const payload = {
          inspectionDate: data.data.dateInspectionPeriod.start.substr(0, 10),
          inspectionType: data.data.type.id,
          inspectorName: data.data.inspectorName
        }
        return payload
      } else {
        console.warn(data)
        return {}
      }
    },
    // click delete inspection form
    async confirmDeleteInspectionForm ({ state, commit, dispatch, getters }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/del/inspection/${state.inspectionId}`
      const response = await api.get({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return true
      } else {
        console.warn(data)
        return false
      }
    },
    // endregion
    // region step 0
    newDamageForm ({ state, commit, dispatch, getters }, payload) {
      commit('SET_STEP', 1)
      commit('SET_PRINCIPAL_ITEM', { id: 0, sector: {}, component: {}, type: {}, score: {} })
    },
    closeAndNewDamageForm ({ state, commit, dispatch, getters }, payload) {
      commit('SET_STEP', 2)
      commit('SET_PRINCIPAL_ITEM', { id: 0, bridgePartId: state.bridgePartId, sector: { id: state.sectorId, no: state.sectorNo }, component: { id: state.componentId, no: state.componentNo }, type: {}, score: {} })
    },
    async fetchComponentDimension ({ state, commit, dispatch, getters }, payload) {
      const materialData = {
        component_id: payload.component_id,
        type: parseInt(state.inspectionType) === 2 ? 'principal' : 'indepth'
      }
      dispatch('getMaterialByComponent', materialData, { root: true })
        .then((data) => {
          commit('SET_REQUIRED_MATERIAL', data || [])
          if (!data || data.length === 0) {
            dispatch('getDimensionField')
          }
        })
      const url = `${process.env.VUE_APP_WS_BASE}/get/bridge-component-dimension/${state.bridgePartId}`
      // eslint-disable-next-line camelcase
      const { sectorId: sector_id, sectorNo: sector_no, componentId: component_id, componentNo: component_no } = state
      const queryDimensionParameter = {
        sector_id,
        sector_no,
        component_id,
        component_no,
        type: parseInt(state.inspectionType) === 2 ? 'principal' : 'indepth'
      }
      const formData = utils.payloadToFormData(queryDimensionParameter)
      const response = await api.post({ url, data: formData })
      const data = response.data
      if (data.message === 'OK') {
        commit('SET_DIMENSION_VALUE', data.data)
        return data.data
      } else {
        console.warn(data)
        return false
      }
    },
    async getOtherComponent ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/list-component-other?sector_id=${payload.sector_id}&bridge_structure_id=${payload.bridge_structure_id}`
      const response = await api.get({ url })
      const data = response.data
      if (data.message === 'OK') {
        state.otherParts = data.data
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async fetchElementNoWeight ({ state, commit, dispatch, getters }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/ref/element_weight`
      const payloadData = {
        ref_bridge_component_id: payload.component_id
      }
      if (payload.component_id === 'PR') {
        payloadData.ref_pier_type_id = getters.currentPier.pier_type_id
      }
      const formData = utils.payloadToFormData(payloadData)
      const response = await api.post({ url, data: formData })
      const data = response.data
      if (data.message === 'OK') {
        commit('SET_ELEMENT_NO_WEIGHT_ARRAY', data.data)
        return data.data
      } else {
        console.warn(data)
        return false
      }
    },
    async fetchDamageMapping ({ state, commit, dispatch, getters }, payload) {
      const apiName = parseInt(state.inspectionType) === 2 ? 'damage-principal-mapping' : 'damage-indepth-mapping'
      const url = `${process.env.VUE_APP_WS_BASE}/get/${apiName}/${payload.component_id}`
      const formData = utils.payloadToFormData({ componentMaterialId: state.materialId, componentPoleId: state.componentPoleId })
      const response = await api.post({ url, data: formData })
      const data = response.data
      if (data.message === 'OK') {
        commit('SET_DAMAGE_ARRAY', data.data)
        return data.data
      } else {
        console.warn(data)
        return false
      }
    },
    async fetchPrincipalForm ({ state, commit, dispatch, getters }, payload) {
      commit('SET_STEP', 2)
      const url = `${process.env.VUE_APP_WS_BASE}/get/${getters.apiName}/${payload.id}`
      const response = await api.get({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        commit('SET_PRINCIPAL_ITEM', { ...data.data, id: payload.id })
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    // endregion
    // region step 2
    async saveInspectionDamage ({ state, commit, dispatch, getters }, payload) {
      const dispatchPayload = {
        inspectionId: state.inspectionId,
        bridgePartId: state.bridgePartId,
        sectorId: state.sectorId,
        sectorNo: state.sectorNo,
        componentId: state.componentId,
        componentNo: state.componentNo,
        damageTypeId: state.damageTypeId,
        scoreId: state.scoreId,
        areaCritical: state.areaCritical,
        componentMaterialId: state.materialId,
        bridge_component_material_id: state.materialId,
        componentPoleId: state.componentPoleId,
        bridge_component_pole_id: state.componentPoleId,
        damageWidth: state.damageWidth,
        damageLength: state.damageLength,
        damageHeight: state.damageHeight,
        damageThickness: state.damageThickness,
        damagePiece: state.damagePiece,
        pointReference: state.pointReference,
        remarks: state.remarks,
        images: state.pathImages.filter(item => !item.id).map(item => item.file),
        imageId: state.pathImages.filter(item => !!item.id).map(item => item.id)
      }
      if (String(state.inspectionType) === '2') {
        dispatchPayload.hasCritical = state.hasCritical ? '1' : '0'
        dispatchPayload.refUnitId = state.principalUnit
        if (state.hasCritical) {
          dispatchPayload.areaCritical = state.areaCritical || '0'
        } else {
          dispatchPayload.areaCritical = '0'
        }
      }
      if (String(state.inspectionType) === '3') {
        dispatchPayload.positionId = getters.bridgePositionId
      }
      const isSuccess = await dispatch('postPrincipal', dispatchPayload)
      return !!isSuccess
    },
    async postPrincipal ({ state, commit, dispatch, getters }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/set/${getters.apiName}/${state.principalId}`
      const formData = utils.payloadToFormData(payload)
      console.log(payload)
      console.log([...formData.entries()])
      const response = await api.post({ url, data: formData })
      const data = response.data
      if (data.message === 'OK') {
        commit('SET_PRINCIPAL_ITEM_ID', data.data)
        return data.data
      } else {
        console.warn(data)
        return false
      }
    },
    async saveDimension ({ state, commit, dispatch, getters }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/set/bridge-component-dimension/${state.bridgePartId}`
      /* eslint-disable */
      const { sectorId: sector_id, sectorNo: sector_no, componentId: component_id, componentNo: component_no } = state
      const data1 = { sector_id, sector_no, component_id, component_no }
      const { dimension_width, dimension_length, dimension_height, dimension_thickness, dimension_piece } = state
      const data2 = { dimension_width, dimension_length, dimension_height, dimension_thickness, dimension_piece }
      const { elementNoWeight: ref_element_weight_id, materialId: ref_pier_material_id, componentPoleId: ref_bridge_component_pole_id } = state
      const data3 = { ref_pier_material_id, ref_bridge_component_pole_id, ref_element_weight_id }
      const formData = utils.payloadToFormData({ ...data1, ...data2, ...data3 })
      const response = await api.post({ url, data: formData })
      const data = response.data
      /* eslint-enable */
      if (data.message === 'OK') {
        return true
      } else {
        console.warn(data)
        return false
      }
    },
    async openHistoryDialog ({ state, commit, dispatch, getters }, payload) {
      commit('SET_HISTORY_DIALOG_DRAWER', true)
      const url = `${process.env.VUE_APP_WS_BASE}/get/${getters.apiNameHistory}/${state.bridgeId}/${state.bridgePartId}`
      const { sectorId, sectorNo, componentId, componentNo } = state
      const formData = utils.payloadToFormData({ sectorId, sectorNo, componentId, componentNo })
      const response = await api.post({ url, data: formData })
      const data = response.data
      if (data.message === 'OK') {
        commit('SET_PRINCIPAL_HISTORY', data.data || [])
        return data.data
      } else {
        console.warn(data)
        return false
      }
    },
    async getDamageIndepthField ({ state, commit, dispatch, getters }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/damage-indepth-field`
      /* eslint-disable */
      const { componentId: component_id, scoreId: score_indepth_id } = state
      const { materialId: bridge_component_material_id, componentPoleId: bridge_component_pole_id } = state
      if (![3, 4].includes(state.inspectionType) || (getters.requiredMaterialWithName.length && !bridge_component_material_id)) {
        return
      }
      let payload_formData = { component_id, score_indepth_id, bridge_component_material_id }
      if (component_id == 'PR' && ['PC'].includes(getters.currentPier.pier_type_id)) {
        payload_formData = { ...payload_formData, bridge_component_pole_id }
      }
      if (component_id == 'PR') {
        const pier_type_id = getters.currentPier.pier_type_id
        payload_formData = { ...payload_formData, pier_type_id }
      }
      /* eslint-enable */
      const formData = utils.payloadToFormData(payload_formData)
      const response = await api.post({ url, data: formData })
      const data = response.data
      if (data.message === 'OK') {
        commit('SET_INPUT_BOOLEAN', data.data)
        return data.data
      } else {
        console.warn(data)
        return false
      }
    },
    // endregion
    async deletePrincipal ({ state, commit, dispatch, getters }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/del/${getters.apiName}/${state.principalId}`
      const response = await api.get({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getDimensionField ({ state, commit, dispatch, getters }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/dimension-field`
      // eslint-disable-next-line camelcase
      const { componentId: component_id, materialId: bridge_component_material_id, componentPoleId: bridge_component_pole_id } = state
      const formData = utils.payloadToFormData({ component_id, materialId: bridge_component_material_id, componentPoleId: bridge_component_pole_id })
      const response = await api.post({ url, data: formData })
      const data = response.data
      if (data.message === 'OK') {
        commit('SET_DIMENSION_BOOLEAN', data.data)
        return data.data
      } else {
        console.warn(data)
        return false
      }
    },
    backupScrollDistance ({ state }) {
      if (state.stepZeroComponent && state.stepZeroComponent.$el) {
        const overflowTarget = document.getElementById('overflowTarget')
        state.stepZeroScrollDistance = overflowTarget.scrollTop
      }
    },
    restoreScrollDistance ({ state }) {
      if (state.stepZeroComponent && state.stepZeroComponent.$el) {
        const overflowTarget = document.getElementById('overflowTarget')
        overflowTarget.scrollTop = state.stepZeroScrollDistance
      }
    }
  },
  mutations: {
    SET_DAMAGE_DETAIL_TAB (state, value) {
      state.damageDetailTab = value
    },
    SET_SURVEY_DRAWER (state, value) {
      state.surveyDrawer = value
    },
    SET_BRIDGE_VIEW (state, value) {
      state.bridgeView = value
    },
    SET_SURVEY_MODE (state, value) {
      state.surveyMode = value
    },
    SET_DRAWER (state, value) {
      state.drawer = value
    },
    SET_BRIDGE_ID (state, value) {
      state.bridgeId = value
    },
    SET_INSPECTION (state, value) {
      state.bridgeId = value.bridgeId
      state.inspectionId = value.id
      state.inspectionType = value.type.id
      if (String(state.inspectionType) === '1' && value.bridge_part_id) {
        state.bridgePartId = parseInt(value.bridge_part_id)
      }
    },
    SET_INSPECTION_ID (state, value) {
      state.inspectionId = value
    },
    SET_INSPECTION_FORM (state, value) {
      state.inspectionType = value.inspectionType
      state.inspectorName = value.inspectorName
      state.inspectionDate = value.inspectionDate
    },
    SET_TYPE (state, value) {
      state.inspectionType = value
    },
    SET_LOADING (state, value) {
      state.loading = value
    },
    STEP_INCREMENT (state) {
      state.step += 1
    },
    STEP_RESET (state) {
      state.step = 0
    },
    SET_STEP (state, value) {
      state.step = value
    },
    SVG_STEP (state, value) {
      state.svgStep = value
    },
    SET_HISTORY_DIALOG_DRAWER (state, value) {
      state.openHistoryDialog = value
    },
    SET_SUGGESTION_DIALOG_DRAWER (state, value) {
      state.openSuggestionDialog = value
    },
    SET_PRINCIPAL_ITEM_LIST (state, value) {
      state.inspectionItemList = value
    },
    SET_PRINCIPAL_SUMMARY (state, value) {
      state.inspectionSummary = value
    },
    SET_OTHERS_SUMMARY (state, value) {
      state.othersSummary = value
    },
    SET_ROUTINE_OVERVIEW_ITEM (state, value) {
      state.routineOverviewItem = value
    },
    SET_ROUTINE_TOP_ITEM (state, value) {
      state.routineTopItem = value
    },
    SET_ROUTINE_BOTTOM_ITEM (state, value) {
      state.routineBottomItem = value
    },
    SET_PRINCIPAL_ITEM_ID (state, value) {
      state.principalId = value
    },
    SET_PRINCIPAL_ITEM (state, value) {
      state.principalId = value.id
      state.bridgePartId = parseInt(value.bridgePartId)
      state.sectorId = value.sector.id || ''
      state.sectorNo = value.sector.no || ''
      state.componentId = value.component.id || ''
      state.componentNo = value.component.no || ''
      state.damageTypeId = value.type.id || ''
      state.scoreId = value.score.id || ''
      state.hasCritical = value.hasCritical ? 1 : 0
      state.areaCritical = parseFloat(value.area_critical || 0)
      state.damageWidth = parseFloat(value.damage_width || 0)
      state.damageLength = parseFloat(value.damage_length || 0)
      state.damageHeight = parseFloat(value.damage_height || 0)
      state.damageThickness = parseFloat(value.damage_thickness || 0)
      state.damagePiece = parseFloat(value.damage_piece || 0)
      state.pointReference = parseFloat(value.point_reference || 0)
      state.remarks = value.remarks || ''
      state.pathImages = (value.pathImages || []).filter(item => !!item.pathImage).map(item => ({ ...item, pathImage: item.pathImage || utils.realAttachmentsPath(item.pathImage) }))
      state.principalUnit = value.principalUnit || ''
      if (parseInt(state.inspectionType) === 2) {
        state.principalUnit = value.unit_type?.id || ''
        // https://mmtech.slack.com/archives/C0KFY310Q/p1662547307596909
        state.materialId = value.material?.id || ''
      }
      switch (String(value.positionId)) {
        case ('2'):
          state.bridgeView = 'bottomView'
          break
        case ('4'):
          state.bridgeView = 'crossSectionView'
          break
        case ('1'):
          state.bridgeView = 'topView'
          break
        case ('3'):
          state.bridgeView = 'sideView'
          break
        default:
          state.bridgeView = ''
          break
      }
    },
    SET_PRINCIPAL_SVG (state, value) {
      state.sectorId = value.sectorID
      state.sectorNo = value.sectorNo
      state.componentId = value.componentID || ''
      state.componentNo = value.componentNo || ''
      state.partsText = value.text || ''
      if (value.otherComponent) {
        state.stepOneComponent.mountSvg = false
        setTimeout(() => {
          state.stepOneComponent.mountSvg = true
        }, 100)
      }
    },
    SET_SELECTED_BRIDGE_PART (state, value) {
      state.bridgePartId = value
    },
    SET_CRITICAL (state, value) {
      state.hasCritical = value
    },
    SET_DAMAGE_TYPE_ID (state, value) {
      state.damageTypeId = value
    },
    SET_SCORE_ID (state, value) {
      state.scoreId = value
    },
    SET_AREA_CRITICAL (state, value) {
      state.areaCritical = value
    },
    SET_DAMAGE_WIDTH (state, value) {
      state.damageWidth = value
    },
    SET_DAMAGE_LENGTH (state, value) {
      state.damageLength = value
    },
    SET_DAMAGE_HEIGHT (state, value) {
      state.damageHeight = value
    },
    SET_DAMAGE_THICKNESS (state, value) {
      state.damageThickness = value
    },
    SET_DAMAGE_PIECE (state, value) {
      state.damagePiece = value
    },
    SET_POINT_REFERENCE (state, value) {
      state.pointReference = value
    },
    SET_REMARKS (state, value) {
      state.remarks = value
    },
    APPEND_IMAGE (state, value) {
      state.pathImages.push({ id: 0, file: value })
    },
    SPLICE_IMAGE (state, value) {
      state.pathImages.splice(value, 1)
    },
    SET_PRINCIPAL_HISTORY (state, value) {
      state.principalHistory = value
    },
    SET_OVERVIEW_ROUTINE_IMAGE (state, value) {
      state.currentImage = value
    },
    SET_OVERVIEW_ROUTINE_PARTS (state, value) {
      state.currentImage = value.currentImage
      state.currentSeq = value.seq
      state.seqDetail = value.seqDetail
      state.imageId = value.imageId || 0
    },
    SET_ROUTINE_MODE (state, value) {
      state.routineMode = value
    },
    SET_STEP_TWO_VALIDATION (state, value) {
      state.stepTwoFormSuccess = value
    },
    SET_STEP_TWO_DIMENSION_SUCCESS (state, value) {
      state.stepTwoDimensionSuccess = value
    },
    SET_REQUIRED_MATERIAL (state, value) {
      state.requiredMaterial = value
    },
    SET_SELECTED_MATERIAL (state, value) {
      state.materialId = value
    },
    SET_SELECTED_POLE (state, value) {
      state.componentPoleId = value
    },
    RESET_DIMENSION (state) {
      state.materialId = ''
      state.componentPoleId = ''
      state.has_width = false
      state.has_length = false
      state.has_height = false
      state.has_thickness = false
      state.has_piece = false
      state.dimension_width = ''
      state.dimension_length = ''
      state.dimension_height = ''
      state.dimension_thickness = ''
      state.dimension_piece = ''
      state.has_input_width = false
      state.has_input_length = false
      state.has_input_height = false
      state.has_input_thickness = false
      state.has_input_piece = false
      state.damageWidth = ''
      state.damageLength = ''
      state.damageHeight = ''
      state.damageThickness = ''
      state.damagePiece = ''
      state.pointReference = ''
      state.remarks = ''
    },
    SET_DIMENSION_BOOLEAN (state, value) {
      state.has_width = value.has_width
      state.has_length = value.has_length
      state.has_height = value.has_height
      state.has_thickness = value.has_thickness
      state.has_piece = value.has_piece
    },
    SET_DIMENSION_VALUE (state, value) {
      state.dimension_width = value?.dimension_width ?? ''
      state.dimension_length = value?.dimension_length ?? ''
      state.dimension_height = value?.dimension_height ?? ''
      state.dimension_thickness = value?.dimension_thickness ?? ''
      state.dimension_piece = value?.dimension_piece ?? ''
      state.elementNoWeight = value?.ref_element_weight_id ?? ''
      if (String(state.inspectionType) !== '2') {
        // https://mmtech.slack.com/archives/C0KFY310Q/p1662547307596909
        state.materialId = value?.ref_pier_material_id ?? ''
      }
      state.componentPoleId = value?.ref_bridge_component_pole_id ?? ''
    },
    SET_DIMENSION_WIDTH (state, value) {
      state.dimension_width = value
    },
    SET_DIMENSION_LENGTH (state, value) {
      state.dimension_length = value
    },
    SET_DIMENSION_HEIGHT (state, value) {
      state.dimension_height = value
    },
    SET_DIMENSION_THICKNESS (state, value) {
      state.dimension_thickness = value
    },
    SET_DIMENSION_PIECE (state, value) {
      state.dimension_piece = value
    },
    SET_INPUT_BOOLEAN (state, value) {
      state.has_input_width = !!value?.has_input_width
      state.has_input_length = !!value?.has_input_length
      state.has_input_height = !!value?.has_input_height
      state.has_input_thickness = !!value?.has_input_thickness
      state.has_input_piece = !!value?.has_input_piece
    },
    SET_ELEMENT_NO_WEIGHT_ARRAY (state, value) {
      state.elementNoWeightArray = value
    },
    SET_ELEMENT_NO_WEIGHT (state, value) {
      state.elementNoWeight = value
    },
    STEP_ZERO_COMPONENT (state, value) {
      state.stepZeroComponent = value
    },
    STEP_ONE_COMPONENT (state, value) {
      state.stepOneComponent = value
    },
    SET_DAMAGE_ARRAY (state, value) {
      state.damageArray = value
    },
    SET_PRINCIPAL_UNIT (state, value) {
      state.principalUnit = value
    }
  }
}

export default inspectionDamageDialog
