<template>
  <v-app>
    <Appbar v-if="isAppbar" />
    <AppbarText v-if="isAppbarText"></AppbarText>
    <Drawer v-if="isAppbar" />
    <v-main>
      <router-view />
      <image-gallery />
      <image-uploader />
      <v-alert
        v-if="isBridgeOverviewLoading"
        type="info"
        icon="mdi-loading mdi-spin-fast"
        class="alertPosition"
      >
        กำลังคำนวนส่วนประกอบสะพาน
      </v-alert>
      <v-alert
        v-if="!!alertMessage"
        type="error"
        class="alertPosition"
        style=""
      >
        {{ alertMessage }}
      </v-alert>
    </v-main>
    <AppFooter />
  </v-app>
</template>

<script>
import Appbar from './layout/Appbar.vue'
import AppFooter from './layout/AppFooter.vue'
import Drawer from '@/layout/Drawer'
import AppbarText from '@/layout/AppbarText'
import ImageGallery from './components/ImageGallery'
import ImageUploader from '@/components/ImageUploader'
import { mapState } from 'vuex'

export default {
  components: {
    ImageUploader,
    ImageGallery,
    AppbarText,
    Drawer,
    Appbar,
    AppFooter
  },
  name: 'App',
  computed: {
    ...mapState({
      alertMessage: 'alertMessage',
      isBridgeOverviewLoading: 'isBridgeOverviewLoading'
    }),
    isAppbar () {
      const rules = [
        'Home',
        'Bridge',
        'Damage',
        'Maintenance',
        'Repair',
        'Budget',
        'Report',
        'Forum',
        'Setting',
        'Forbidden'
      ]
      return rules.includes(this.$route.name)
    },
    isAppbarText () {
      const rules = ['DamageDetail']
      return rules.includes(this.$route.name)
    }
  },
  async mounted () {
    await this.$store.dispatch('user/loadInternalStorage') // uuid load here
  },
  watch: {
    '$route.name' (to, from) {
      this.$store.commit('appbarText/SET_ROUTE', from)
    }
  }
}
</script>

<style scoped>
.alertPosition {
  position: absolute;
  bottom: 8px;
  left: 16px;
  margin: 0;
  z-index: 500;
}
</style>

<style>
.v-application,
.v-application .text-caption {
  font-family: 'Roboto', 'Prompt', sans-serif !important;
}
.v-application--wrap {
  height: 100vh;
}
/* .v-main {
  height: 100%;
} */
.a_forRightClick {
  text-decoration: unset;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mdi-spin-fast:before {
  animation: mdi-spin 0.85s infinite linear;
}
.my__v-card-title {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px !important;
}
.my__v-card-text {
  color: black !important;
  padding: 0 !important;
  font-size: 1rem;
}
.my__v-subheader {
  color: black !important;
}

.drop-shadow {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
}

@media (min-width: 600px) {
  .underline-hover:hover {
    text-decoration: underline;
  }
  .pointer-hover:hover {
    cursor: pointer;
  }
  .light-blue-hover:hover {
    background-color: #eef3ff !important;
  }
}

@media (min-width: 600px) {
  /* applied to Firefox */
  :root {
    scrollbar-color: #d6dee1 transparent !important;
    scrollbar-width: thin !important;
  }
  /* applied to webkit-like browser eg. Chrome, Edge, ... */
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .v-menu__content::-webkit-scrollbar-track {
    background-color: white;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
}
.progress-green {
  position: relative;
  height: 5px;
  width: 100%;
  background-color: #92c22d;
}
.progress-green .color-green {
  position: absolute;
  background-color: green;
  width: 0;
  height: 5px;
  animation: progres 3s infinite linear;
}
.progress-red {
  position: relative;
  height: 5px;
  width: 100%;
  background-color: #f44336;
}
.progress-red .color-red {
  position: absolute;
  background-color: #bd0000;
  width: 0;
  height: 5px;
  animation: progres 3s infinite linear;
}
.form-text {
  font-size: 1rem;
  /* color: black; */
}
@keyframes progres {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
