import axios from 'axios'
import store from '../../store'

axios.interceptors.response.use(
  (response) => {
    const ret = response.data
    if (!ret.status && String(ret.message || '').toLowerCase() === 'bad request') {
      store.commit('SET_ALERT_MESSAGE', `Bad Request, at:${response.config.url}`)
      setTimeout(() => {
        store.commit('SET_ALERT_MESSAGE', false)
      }, 5000)
    }
    return response
  },
  (error) => {
    console.warn(error.toJSON())
    store.commit('SET_ALERT_MESSAGE', `${error.message}, at:${error.config.url}`)
    setTimeout(() => {
      store.commit('SET_ALERT_MESSAGE', false)
    }, 5000)
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ ...error, data: { message: error.message } })
  }
)

axios.interceptors.request.use((config) => {
  const uuid = store.state.user.uuid
  const data = config.data
  if (!uuid) { return config }
  if (data instanceof FormData) {
    data.append('uuid', uuid)
    if (process.env.NODE_ENV !== 'production') {
      const url = new URL(config.url)
      const payload = [...data.entries()]
      payload.forEach(([key, value]) => {
        if (!value.name) {
          data.delete(key)
          url.searchParams.append(key, value)
        }
      })
      config.url = url.toString()
    }
    config.data = data
  } else {
    const url = new URL(config.url)
    url.searchParams.append('uuid', uuid)
    config.url = url.toString()
  }
  return config
})

export default {
  get (config) {
    const option = {
      url: config.url,
      data: config.data,
      method: 'get'
    }
    return this.doAxios(option)
  },
  post (config) {
    const option = {
      url: config.url,
      data: config.data,
      method: 'post'
    }
    return this.doAxios(option)
  },
  doAxios (config) {
    const defaultOption = {
      headers: { 'Content-Type': 'multipart/form-data' },
      validateStatus: function (status) {
        return true
      }
    }
    return axios({ ...defaultOption, ...config })
  }
}
