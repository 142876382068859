<template>
  <v-footer fixed dark app>
    <v-col class="text-center text-caption ma-0 pa-0" cols="12">
      2022 - DOH-BMMS ||
      <a @click="documentDialog = true" class="white--text underline-hover">
        ดาวน์โหลดคู่มือ
      </a>
      ||
      <a @click="contactDialog = true" class="white--text underline-hover">
        ติดต่อเรา
      </a>
    </v-col>
    <ContactDialog v-model="contactDialog" @cancel="closeContactDialog" />
    <DocumentDownloadDialog
      v-model="documentDialog"
      @cancel="closeDocumentDialog"
    />
  </v-footer>
</template>

<script>
export default {
  components: {
    ContactDialog: () => import('@/components/dialog/ContactDialog.vue'),
    DocumentDownloadDialog: () =>
      import('@/components/dialog/DocumentDownloadDialog.vue')
  },
  data: () => ({
    contactDialog: false,
    documentDialog: false
  }),
  methods: {
    closeContactDialog () {
      this.contactDialog = false
    },
    closeDocumentDialog () {
      this.documentDialog = false
    }
  }
}
</script>
