<template>
  <v-app-bar app>
    <div class="appButtonContainer d-flex align-center justify-center">
      <v-icon @click="back"> mdi-arrow-left</v-icon>
    </div>
    <v-app-bar-title v-if="header && subHeader">
      <template v-slot:default>
        <div class="ml-4" style="width: 400px">
          <div class="primary--text font-weight-bold">
            {{ header }}
          </div>
          <div class="text-caption">
            {{ subHeader }}
          </div>
        </div>
      </template>
    </v-app-bar-title>
  </v-app-bar>
</template>

<script>
export default {
  name: 'AppbarText',
  computed: {
    defaultPreviousRoute () {
      return this.$store.state.appbarText.defaultPreviousRoute
    },
    fromRoute () {
      return this.$store.state.appbarText.fromRoute
    },
    header () {
      return this.$store.state.appbarText.header
    },
    subHeader () {
      return this.$store.state.appbarText.subHeader
    }
  },
  methods: {
    back () {
      this.$store.dispatch('appbarText/resetState')
      // if come from other site, back to homepage
      // if come from same site, back to previous router
      if (!this.fromRoute) {
        this.$router.push({ name: this.defaultPreviousRoute })
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style scoped>
::v-deep .v-toolbar__content, .v-toolbar__extension {
  padding: 0;
}
.appButtonContainer {
  width: 64px;
  height: 100%;
}
</style>
