const imageUploader = {
  namespaced: true,
  state: {
    drawer: false,
    header: 'Header',
    subHeader: 'SubHeader',
    caption: '',
    imageObject: null,
    showRemove: true,
    callback: null,
    removeCallback: null,
    showResponseDialog: false,
    onOpen: []
  },
  actions: {
    async save ({ state, dispatch, commit }, payload) {
      return await state.callback(state.imageObject)
    },
    async remove ({ state, dispatch, commit }, payload) {
      return await state.removeCallback()
    }
  },
  mutations: {
    SET_DRAWER (state, value) {
      state.drawer = value
    },
    SET_HEADER (state, value) {
      state.header = value
    },
    SET_SUB_HEADER (state, value) {
      state.subHeader = value
    },
    SET_FILE (state, value) {
      state.imageObject = value
    },
    SET_SHOW_REMOVE (state, value) {
      state.showRemove = value
    },
    SET_CALLBACK (state, value) {
      state.callback = value
    },
    /**
     * @param {{}} state
     * @param {{file, callback, header, subHeader, showRemove, drawer, removeCallback, caption, showResponseDialog, onOpen }} value
     */
    MUTATE_COMPONENT (state, value) {
      state.onOpen = value.onOpen || []
      state.header = value.header || 'header'
      state.subHeader = value.subHeader || 'subHeader'
      state.imageObject = value.file || null
      state.showRemove = value.showRemove || false
      state.callback = value.callback || function () {}
      state.caption = (value.caption === undefined || value.caption === null) ? 'caption' : value.caption
      state.removeCallback = value.removeCallback || function () {}
      state.drawer = value.drawer || true
      state.showResponseDialog = value.showResponseDialog || false
    }
  }
}

export default imageUploader
