<template>
  <div>
    <!-- :mini-variant="$vuetify.breakpoint.sm || $vuetify.breakpoint.md" -->
    <!-- :mini-variant.sync="isExpand" -->
    <v-navigation-drawer
      v-model="drawer"
      app
      :permanent="$vuetify.breakpoint.sm || $vuetify.breakpoint.md"
      :expand-on-hover="$vuetify.breakpoint.sm || $vuetify.breakpoint.md"
      :mini-variant="$vuetify.breakpoint.sm || $vuetify.breakpoint.md"
      width="300"
    >
      <!-- $vuetify.breakpoint.sm || $vuetify.breakpoint.md -->
      <v-card
        color="primary"
        height="100%"
        class="rounded-0 d-flex flex-column"
      >
        <v-card class="elevation-0 rounded-0">
          <v-list-item>
            <v-img
              class="my-4 mr-2"
              width="55"
              src="../assets/img/bmms_logo.png"
            ></v-img>
            <v-list-item-title class="font-weight-bold primary--text text-h5">
              M-BMMS
            </v-list-item-title>
          </v-list-item>
          <v-divider class="mx-4"></v-divider>
          <v-list-item v-if="$vuetify.breakpoint.lgAndUp" class="mt-2">
            <v-list-item-title class="font-weight-bold text-h5">
              <div class="primary--text text-caption">
                <div>Bridge Maintenance & Management System</div>
                <div>ระบบบริหารทรัพย์สินโครงสร้างพื้นฐานงานสะพาน</div>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-card>
        <v-list nav dense class="overflow-y-auto mb-auto">
          <v-list-item-group active-class="red accent-2">
            <template v-for="(item, index) in listItems">
              <v-list-item
                v-if="$store.getters[item.permissions]"
                :key="index"
                :to="item.path"
                :active-class="item.name === 'รายงาน' ? '_' : undefined"
                :style="item.name === 'รายงาน' ? 'opacity: 1;' : ''"
                exact
                @click="openReportDialog($event, item.name)"
              >
                <template>
                  <v-list-item-action>
                    <v-list-item-action-text v-text="item.action" />
                    <v-icon class="white--text">
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="white--text">
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
        <div class="white--text">
          <v-list-item two-line>
            <v-list-item-action class="ma-0" style="min-width: 0">
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="white--text">
                แขวงทางหลวงพิเศษระหว่างเมือง
              </v-list-item-title>
              <v-list-item-subtitle
                class="white--text text-caption"
                v-html="contact"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-card>
      <export-report-dialog
        :show="reportDialogShow"
        @update:show="updateReportDialog"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import ExportReportDialog from '@/components/dialog/ExportReportDialog'

export default {
  components: { ExportReportDialog },
  data: () => ({
    listItems: [
      {
        name: 'หน้าหลัก',
        path: '/',
        icon: '$MainIcon',
        permissions: 'access_homepage'
      },
      {
        name: 'บัญชีสะพาน',
        path: '/bridge',
        icon: '$BridgeIcon',
        permissions: 'access_inventory'
      },
      {
        name: 'ความเสียหาย',
        path: '/damage',
        icon: '$DamageIcon',
        permissions: 'access_inspection'
      },
      {
        name: 'ประวัติซ่อมบำรุง',
        path: '/maintenance',
        icon: '$MaintenanceIcon',
        permissions: 'access_history'
      },
      {
        name: 'รายละเอียดการซ่อมบำรุง',
        path: '/repair',
        icon: 'mdi-progress-wrench mdi-flip-h',
        permissions: 'access_repair'
      },
      {
        name: 'จัดสรรงบประมาณ',
        path: '/budget',
        icon: '$BudgetIcon',
        permissions: 'access_budget'
      },
      {
        name: 'รายงาน',
        _path: '/report',
        icon: '$ReportIcon',
        permissions: 'access_report'
      },
      // {
      //   name: 'ห้องสนทนา',
      //   path: '/forum',
      //   icon: '$ChatIcon',
      //   permissions: 'access_forum'
      // },
      {
        name: 'ตั้งค่า',
        path: '/setting',
        icon: '$SettingIcon',
        permissions: 'access_settings'
      }
    ],
    reportDialogShow: false,
    contact: `ถนนหลวงแพ่ง แขวงทับยาว <br>
              เขตลาดกระบัง กรุงเทพฯ 10520 <br>
              โทร 02-360-7865 <br>
              Fax.02-360-7865`
  }),
  computed: {
    drawer: {
      get () {
        return this.$store.state.layout.drawer
      },
      set (value) {
        this.$store.commit('layout/SET_DRAWER', value)
      }
    }
  },
  mounted () {
    if (this.$vuetify.breakpoint.lgAndUp) this.drawer = true
    else this.drawer = false
  },
  methods: {
    openReportDialog (event, name) {
      console.log(event, name)
      switch (name) {
        case 'รายงาน':
          this.reportDialogShow = true
          return false
        default:
          break
      }
    },
    updateReportDialog (emit) {
      this.reportDialogShow = !!emit
    }
  }
}
</script>

<style lang="scss" scoped></style>
