const imageGallery = {
  namespaced: true,
  state: {
    drawer: false,
    header: 'Header',
    subHeader: 'SubHeader',
    images: ['https://picsum.photos/id/1/500/300', 'https://picsum.photos/id/2/500/300', 'https://picsum.photos/id/3/500/300'],
    imgIndex: 0
  },
  mutations: {
    SET_DRAWER (state, value) {
      state.drawer = value
    },
    SET_IMAGES (state, value) {
      state.images = value
    },
    SET_IMAGE_INDEX (state, value) {
      state.imgIndex = value
    }
  }
}

export default imageGallery
