<template>
  <v-dialog
    v-model="localShow"
    max-width="900"
    :fullscreen="$vuetify.breakpoint.xs"
    scrollable
  >
    <v-card>
      <v-card-title class="primary white--text d-flex justify-space-between">
        <div>ส่งออกรายงาน</div>
        <v-icon color="white" @click="localShow = false"> mdi-close </v-icon>
      </v-card-title>
      <v-card-text class="my__v-card-text pa-3">
        <v-form class="pa-3">
          <v-row>
            <v-col cols="12" sm="6">
              <h3 class="primary--text">
                ขั้นตอนที่ 1: เลือกรายงาน
                <span class="pl-1">*</span>
              </h3>
              <div class="pt-2">
                <v-radio-group v-model="exportForm.option">
                  <v-radio
                    v-for="item in reportOption"
                    :key="item.label"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                    <template v-slot:label>
                      <span :class="!item.disabled ? 'black--text' : ''">{{
                        item.label
                      }}</span>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <h3 class="primary--text">
                ขั้นตอนที่ 2: ระบุเงื่อนไขและดาวน์โหลด
                <span class="pl-1">*</span>
              </h3>
              <v-row class="mt-3" align="center">
                <v-col cols="5"
                  >หมายเลขทางหลวง
                  <span class="red--text pl-1">*</span>
                </v-col>
                <v-col cols="7">
                  <v-select
                    v-model="exportForm.road_id"
                    :items="itemLists.road"
                    placeholder="เลือก"
                    item-text="label"
                    item-value="road_id"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="5"
                  >ตอนควบคุม
                  <span class="red--text pl-1">*</span>
                </v-col>
                <v-col cols="7">
                  <v-select
                    v-model="exportForm.section_part_id"
                    :items="itemLists.section_part"
                    placeholder="เลือก"
                    item-text="label"
                    item-value="section_part_id"
                    outlined
                    dense
                    hide-details
                    no-data-text="โปรดเลือกหมายเลขทางหลวง"
                    :loading="isLoadingSectionPart"
                  >
                  </v-select>
                </v-col>
                <v-col cols="5"
                  >สะพาน
                  <span class="red--text pl-1">*</span>
                </v-col>
                <v-col cols="7">
                  <v-autocomplete
                    v-model="exportForm.bridge_id"
                    :items="bridgeListBySectionPart"
                    placeholder="เลือก"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    no-data-text="ไม่มีสะพานให้เลือก"
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5"
                  >รูปแบบการส่งออกข้อมูล
                  <span class="red--text pl-1">*</span>
                </v-col>
                <v-col cols="7">
                  <v-radio-group v-model="exportForm.file" class="mt-0">
                    <v-radio
                      v-for="item in exportOption"
                      :key="item.label"
                      :value="item.value"
                    >
                      <template v-slot:label>
                        <v-icon :color="item.color">{{ item.icon }}</v-icon>
                        <span class="black--text ml-3">{{ item.label }}</span>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions class="d-flex justify-end pr-7">
        <v-btn
          :disabled="
            !(exportForm.option && exportForm.bridge_id && exportForm.file)
          "
          color="success"
          :href="getReport()"
          target="_blank"
        >
          ดำเนินการ
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import utils from '@/assets/js/utils'
export default {
  name: 'ExportReportDialog',
  props: ['show'],
  data: () => ({
    localShow: false,
    reportOption: [
      { label: 'รายงาน SI&A', value: 'SI_A' },
      { label: 'รายงาน S.R.', value: 'bridge_SR01' }
    ],
    exportOption: [
      {
        label: 'HTML (preview)',
        value: 'html',
        icon: 'mdi-file-search-outline',
        color: 'blue'
      },
      {
        label: 'PDF',
        value: 'pdf',
        icon: 'mdi-file-pdf-box',
        color: '#EA4C3A'
      },
      {
        label: 'XLSX (MS Excel)',
        value: 'xlsx',
        icon: 'mdi-file-excel-box',
        color: '#08743B'
      }
    ],
    itemLists: {},
    exportForm: {},
    isLoadingSectionPart: false,
    getKm: utils.getKm
  }),
  computed: {
    bridgeListBySectionPart () {
      if (this.exportForm.section_part_id) {
        const bridgeList = this.itemLists.bridgeList.filter(
          (bridge) =>
            bridge.section_part_id === parseInt(this.exportForm.section_part_id)
        )
        return bridgeList
      } else {
        return []
      }
    }
  },
  mounted () {
    this.localShow = false
    this.localShow = this.show
    this.getRefRoad()
    this.getBridgeList()
  },
  watch: {
    show (to, from) {
      this.localShow = !!this.show
    },
    localShow (to, from) {
      if (!to) {
        this.exportForm = {}
        this.$emit('update:show', false)
      }
    },
    'exportForm.road_id' (newVal, oldVal) {
      if (newVal) {
        this.getSectionPart(newVal)
      } else {
        delete this.exportForm.section_part_id
        this.itemLists.section_part = []
      }
    },
    bridgeListBySectionPart (newVal, oldVal) {
      const checkid = newVal.some((id) => id.id === this.exportForm.bridge_id)
      if (!checkid) {
        delete this.exportForm.bridge_id
      }
    }
  },
  methods: {
    async getRefRoad () {
      const roads = await this.$store.dispatch('ref/getRef', {
        method: 'v-mbmms-road-exist-only',
        filter: {}
      })
      for (const road of roads) {
        road.label = `${parseInt(road.code)}`
      }
      this.itemLists.road = roads
    },
    async getSectionPart (roadid) {
      this.isLoadingSectionPart = true
      const controls = await this.$store.dispatch('ref/getRef', {
        method: `v-mbmms-section-part-exist-only?filters=road_id:${roadid}`
      })
      for (const control of controls) {
        control.label = `${control.code} - ${control.name}`
      }
      this.itemLists.section_part = controls
      const checkSection = controls.find(
        (control) => control.section_part_id === this.exportForm.section_part_id
      )
      if (!checkSection) {
        this.exportForm.section_part_id = null
        delete this.exportForm.section_part_id
      }
      this.isLoadingSectionPart = false
    },
    async getBridgeList () {
      const bridgeList = await this.$store.dispatch('getBridgeList', {})
      this.itemLists.bridgeList = []
      bridgeList.forEach((section) => {
        this.itemLists.bridgeList.push({
          header: `ทางหลวงหมายเลข ${parseInt(section.code_road)} - ${
            section.name
          }`,
          section_part_id: section.section_part_id
        })
        this.itemLists.bridgeList.push(
          ...section.items.map((b) => ({
            ...b,
            name: `[${this.getKm(b.km)}] ${b.name} ${
              b.direction ? `(${b.direction})` : ''
            }`,
            section_part_id: section.section_part_id
          }))
        )
      })
    },
    getReport () {
      return `${process.env.VUE_APP_REPORT}/Reports/M_BMMS_reports/${this.exportForm.option}.${this.exportForm.file}?bridge_id=${this.exportForm.bridge_id}`
    }
  }
}
</script>

<style scoped>
.exportReportTable tr td {
  padding: 4px 6px;
}

.exportReportTable tr td:first-child {
  text-align: right;
}
</style>
