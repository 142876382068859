import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import LongdoMap from 'longdo-map-vue'
import HighChartsVue from 'highcharts-vue'
import store from './store'

// Global style
import './assets/css/global.scss'

Vue.config.productionTip = false
Vue.use(LongdoMap, {
  load: {
    apiKey: process.env.VUE_APP_LONGDO_MAP_API_KEY
  }
})
Vue.use(HighChartsVue)

// disable ctrl + s (save page)
document.addEventListener('keydown', (e) => {
  if (e.key === 's' && e.ctrlKey) {
    e.preventDefault()
  }
})

store.dispatch('user/validateSessionData')
  .then(() => {
    new Vue({
      router,
      vuetify,
      store,
      render: (h) => h(App)
    }).$mount('#app')
  })
