import axios from 'axios'
import api from '../../assets/js/api'

const maintenance = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getBridgeRepairAllocateBudgetList (
      { commit, state, dispatch },
      { filter, budgetYear }
    ) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(filter)) {
        if (key === 'ref_repair_ids') {
          value.forEach((repair) => {
            params.append('ref_repair_ids[]', repair)
          })
        } else if (key === 'ref_bridge_component_ids') {
          value.forEach((component) => {
            params.append('ref_bridge_component_ids[]', component)
          })
        } else if (value !== null && value !== undefined) {
          params.append(key, value)
        }
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/get/bridge-repair-allocate-budget-list?budget_year=${budgetYear}&${params.toString()}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async setSequenceBridgeRepairAllocateBudgetList (
      { commit, state, dispatch },
      payload
    ) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(payload)) {
        if (key === 'sorted_ids') {
          value.forEach((id) => {
            params.append('sorted_ids[]', id)
          })
        } else if (value !== null && value !== undefined) {
          params.append(key, value)
        }
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/set/sequence-bridge-repair-allocate-budget-list?${params.toString()}`
      const response = await api.post({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },

    async getBudgetAllocationList ({ commit, state, dispatch }) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/budget-allocation-list`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },

    async getDataGraphBudget ({ commit, state, dispatch }) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/data-graph-budget`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async setBudgetAllocationByYear ({ commit, state, dispatch }, { budgetYear, budget, requestBudget }) {
      const url = `${process.env.VUE_APP_WS_BASE}/set/budget-allocation-by-year/${budgetYear}?budget=${budget}&request_budget=${requestBudget}`
      const response = await api.post({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return false
      }
    },

    async delBudgetAllocationByYear ({ commit, state, dispatch }, { budgetYear }) {
      const url = `${process.env.VUE_APP_WS_BASE}/del/budget-allocation-by-year/${budgetYear}`
      const response = await api.post({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return false
      }
    }
  },
  getters: {}
}

export default maintenance
