import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home')
  },
  {
    path: '/403',
    name: 'Forbidden',
    component: () => import(/* webpackChunkName: "Forbidden" */'../views/Forbidden')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login')
  },
  {
    path: '/bridge',
    name: 'Bridge',
    component: () => import(/* webpackChunkName: "Bridge" */ '../views/Bridge')
  },
  {
    path: '/damage',
    name: 'Damage',
    component: () => import(/* webpackChunkName: "Damage" */ '../views/Damage')
  },
  {
    path: '/damageDetail',
    name: 'DamageDetail',
    component: () =>
      import(/* webpackChunkName: "DamageDetail" */ '../views/DamageDetail')
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () =>
      import(/* webpackChunkName: "Maintenance" */ '../views/Maintenance')
  },
  {
    path: '/repair',
    name: 'Repair',
    component: () => import(/* webpackChunkName: "Repair" */ '../views/Repair')
  },
  {
    path: '/budget',
    name: 'Budget',
    component: () => import(/* webpackChunkName: "Budget" */ '../views/Budget')
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import(/* webpackChunkName: "Report" */ '../views/Report')
  },
  {
    path: '/forum',
    name: 'Forum',
    component: () => import(/* webpackChunkName: "Forum" */ '../views/Forum')
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () =>
      import(/* webpackChunkName: "Setting" */ '../views/Setting')
  },
  {
    path: '/inventory',
    component: () =>
      import(/* webpackChunkName: "inventory" */ '../views/Inventory'),
    children: [
      {
        path: '',
        name: 'InventoryDetail',
        component: () =>
          import(/* webpackChunkName: "inventory-detail" */ '../views/Inventory/Detail.vue')
      },
      {
        path: 'forecast',
        name: 'InventoryForecast',
        component: () =>
          import(/* webpackChunkName: "inventory-forecast" */ '../views/Inventory/Forecast.vue')
      },
      {
        path: '3d',
        name: 'Inventory3D',
        component: () =>
          import(/* webpackChunkName: "inventory-3d" */ '../views/Inventory/3d.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function getRequiredPermission (to) {
  switch (to.name) {
    case 'Home':
      return 'access_homepage'
    case 'Bridge':
      return false
    case 'InventoryDetail':
      return false
    case 'InventoryForecast':
      return false
    case 'Inventory3D':
      return 'access_inventory'
    case 'Damage':
      return false
    case 'DamageDetail':
      return 'access_inspection'
    case 'Maintenance':
      return 'access_history'
    case 'Repair':
      return 'access_repair'
    case 'Budget':
      return 'access_budget'
    case 'Report':
      return 'access_report'
    case 'Forum':
      return 'access_forum'
    case 'Setting':
      return 'access_settings'
    default:
      return false
  }
}

router.beforeEach(async (to, from, next) => {
  await store.dispatch('user/loadInternalStorage')
  const loggedIn = !!store.state.user.uuid
  if (loggedIn && to.name === 'Login') {
    next({ name: 'Home' })
  } else if (!loggedIn && to.name !== 'Login') {
    next({ name: 'Login' })
    console.warn('Navigation fail :: Login required')
    return false
  } else if (!loggedIn) {
    next()
  }
  const requiredPermission = getRequiredPermission(to)
  if (requiredPermission === false) {
    next()
    return true
  }
  const hasPermission = store.getters[requiredPermission]
  if (hasPermission) {
    next()
    return true
  }
  if (!hasPermission) {
    console.warn('Navigation fail :: permission required')
    const errorParams = {
      request: to.name,
      require: requiredPermission,
      userEmail: store.state.user.email,
      requestTime: new Date().toLocaleString('th-TH')
    }
    next({ name: 'Forbidden', params: errorParams })
  }
})

//
// router.beforeEach(async (to, from, next) => {
//   const promise = store.dispatch('user/loadInternalStorage')
//   const requiredPermission = getRequiredPermission(to)
//   await promise
//   const currentPermission = store.state.user.uuid
//   if (requiredPermission === false) {
//     if (currentPermission) {
//       next({ name: 'Home' })
//     } else {
//       if (to.name === 'Login') {
//         next()
//       } else {
//         next({ name: 'Login' })
//       }
//     }
//   } else {
//     if (currentPermission) {
//       if (store.getters[requiredPermission]) {
//         if (to.query.id && isNaN(to.query.id)) {
//           next({ name: 'Home' })
//         }
//         next()
//       } else next({ name: 'Home' })
//     } else next({ name: 'Login' })
//   }
// })

export default router
