<template>
  <v-app-bar :clipped-left="false" class="white" app>
    <div
      v-if="logoPermanent"
      class="appButtonContainer primary d-flex align-center justify-center"
    >
      <v-icon class="white--text" @click.stop="drawer = !drawer">
        mdi-menu
      </v-icon>
    </div>
    <div
      v-if="logoPermanent || !$vuetify.breakpoint.lgAndUp"
      class="d-flex align-center"
    >
      <div class="d-flex align-center justify-center mx-3">
        <v-img max-width="40" src="../assets/img/bmms_logo.png" />
      </div>
      <div>
        <div
          class="primary--text font-weight-bold"
          style="font-size: 1.125rem; line-height: 1rem"
        >
          M-BMMS
        </div>
        <div
          class="d-none d-sm-block text-caption ma-0 pa-0 primary--text"
          style="line-height: 1rem"
        >
          ระบบบริหารทรัพย์สินโครงสร้างพื้นฐานงานสะพาน
        </div>
      </div>
    </div>
    <v-spacer />
    <div v-if="uuid" class="d-flex mr-4">
      <v-menu offset-y origin="center center" transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-card
            class="d-flex pa-2"
            color="transparent"
            data-ref="avatar"
            flat
            v-bind="attrs"
            v-on="on"
          >
            <v-avatar size="40">
              <img v-if="avatar" :src="avatar" alt="profile" />
              <v-icon v-else size="40"> mdi-account-circle </v-icon>
            </v-avatar>
            <div v-if="$vuetify.breakpoint.smAndUp" class="ml-2">
              <div class="primary--text font-weight-medium">
                {{ fullname }}
              </div>
              <div class="text-caption">{{ position }}</div>
            </div>
          </v-card>
        </template>
        <div class="white rounded-lg elevation-3 px-2">
          <v-list>
            <v-list-item-group>
              <v-list-item data-ref="logout_btn" @click="doLogout">
                Log Out
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-menu>
    </div>
    <div v-else class="pr-4" @click="goLoginPage">
      <a>เข้าสู่ระบบ</a>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('user', ['uuid', 'fullname', 'position', 'avatar']),
    drawer: {
      get () {
        return this.$store.state.layout.drawer
      },
      set (value) {
        this.$store.commit('layout/SET_DRAWER', value)
      }
    },
    logoPermanent () {
      return !(this.drawer && this.$vuetify.breakpoint.smAndUp)
    }
  },
  methods: {
    goLoginPage () {
      this.$router.push({ path: '/login' })
    },
    async doLogout () {
      const isSuccess = await this.$store.dispatch('user/doLogout')
      if (isSuccess) {
        await this.$router.push({ name: 'Login' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.appButtonContainer {
  width: 64px;
  height: 100%;
}

::v-deep .v-toolbar__content,
.v-toolbar__extension {
  padding: 0;
}
</style>
