import axios from 'axios'
import api from '../../assets/js/api'

const maintenance = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getMaintenancePlanList ({ commit, state, dispatch }, { filter }) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(filter)) {
        if (key === 'bridge_ids') {
          value.forEach((brige) => {
            params.append('bridge_ids', brige)
          })
        } else if (value !== null && value !== undefined) {
          params.append(key, value)
        }
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/get/history-maintenance/maintenance-plan-list?${params.toString()}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getMaintenanceLandList ({ commit, state, dispatch }, { filter }) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(filter)) {
        if (value) params.append(key, value)
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/get/history-maintenance/maintenance-land-list${
        params.toString() && '?' + params.toString()
      }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getMaintenanceContractList ({ commit, state, dispatch }, { filter }) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(filter)) {
        if (key === 'bridge_ids') {
          value.forEach((brige) => {
            params.append('bridge_ids[]', brige)
          })
        } else if (value !== null && value !== undefined) {
          params.append(key, value)
        }
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/get/history-maintenance/maintenance-contract-list${
        params.toString() && '?' + params.toString()
      }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getMaintenanceContractPriceList (
      { commit, state, dispatch },
      { filter }
    ) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(filter)) {
        if (value) params.append(key, value)
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/get/history-maintenance/maintenance-contract-price-list${
        params.toString() && '?' + params.toString()
      }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getMaintenanceContractSummaryList (
      { commit, state, dispatch },
      { filter }
    ) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(filter)) {
        if (value) params.append(key, value)
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/get/history-maintenance/maintenance-contract-summary-list${
        params.toString() && '?' + params.toString()
      }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getMaintenancePlan (
      { commit, state, dispatch },
      { maintenancePlanId }
    ) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/history-maintenance/maintenance-plan/${maintenancePlanId}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getMaintenanceContractDetailList (
      { commit, state, dispatch },
      payload
    ) {
      const url = `${process.env.VUE_APP_WS_BASE}/get/history-maintenance/maintenance-contract-detail-list/${payload.id}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },

    // set
    async setMaintenancePlan ({ commit, state, dispatch }, payload) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(payload)) {
        if (key === 'bridge_ids') {
          value.forEach((brige) => {
            params.append('bridge_ids[]', brige)
          })
        } else if (value !== null && value !== undefined) {
          params.append(key, value)
        }
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/set/history-maintenance/maintenance-plan/${
        payload.id
      }?${params.toString()}`
      const response = await api.post({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async setMaintenanceLand ({ commit, state, dispatch }, payload) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(payload)) {
        if (value !== null && value !== undefined) {
          params.append(key, value)
        }
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/set/history-maintenance/maintenance-land/${
        payload.id
      }?${params.toString()}`
      const response = await api.post({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async setMaintenanceContractSummary ({ commit, state, dispatch }, payload) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(payload)) {
        if (value !== null && value !== undefined) {
          params.append(key, value)
        }
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/set/history-maintenance/maintenance-contract-summary/${
        payload.id
      }?${params.toString()}`
      const response = await api.post({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async setMaintenanceContract ({ commit, state, dispatch }, payload) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(payload)) {
        if (key === 'bridge_ids') {
          value.forEach((brige) => {
            params.append('bridge_ids[]', brige)
          })
        } else if (value !== null && value !== undefined) {
          params.append(key, value)
        }
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/set/history-maintenance/maintenance-contract/${
        payload.id
      }?${params.toString()}`
      const response = await api.post({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async setMaintenanceContractDetail (
      { commit, state, dispatch },
      { maintenanceContractId, formDetail }
    ) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(formDetail)) {
        if (value !== null && value !== undefined) {
          params.append(key, value)
        }
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/set/history-maintenance/maintenance-contract-detail/${maintenanceContractId}?${params.toString()}`
      const response = await api.post({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async setMaintenanceContractPrice ({ commit, state, dispatch }, payload) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(payload)) {
        if (value !== null && value !== undefined) {
          params.append(key, value)
        }
      }
      const url = `${
        process.env.VUE_APP_WS_BASE
      }/set/history-maintenance/maintenance-contract-price/${
        payload.id
      }?${params.toString()}`
      const response = await api.post({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },

    // delete
    async delMaintenancePlan ({ state, commit, dispatch }, { id }) {
      const url = `${process.env.VUE_APP_WS_BASE}/del/history-maintenance/maintenance-plan/${id}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        return false
      }
    },
    async delMaintenanceLand ({ state, commit, dispatch }, { id }) {
      const url = `${process.env.VUE_APP_WS_BASE}/del/history-maintenance/maintenance-land/${id}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        return false
      }
    },
    async delMaintenanceContractSummary ({ state, commit, dispatch }, { id }) {
      const url = `${process.env.VUE_APP_WS_BASE}/del/history-maintenance/maintenance-contract-summary/${id}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        return false
      }
    },
    async delMaintenanceContract ({ state, commit, dispatch }, { id }) {
      const url = `${process.env.VUE_APP_WS_BASE}/del/history-maintenance/maintenance-contract/${id}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        return false
      }
    },
    async delMaintenanceContractPrice ({ state, commit, dispatch }, { id }) {
      const url = `${process.env.VUE_APP_WS_BASE}/del/history-maintenance/maintenance-contract-price/${id}`
      const response = await axios({ url: url })
      const data = response.data
      if (data.message === 'OK') {
        return data.data
      } else {
        return false
      }
    }
  },
  getters: {}
}

export default maintenance
