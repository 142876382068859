import utils from '../../assets/js/utils'
import api from '../../assets/js/api'
import Vue from 'vue'

const user = {
  namespaced: true,
  state: {
    email: '',
    permission: [],
    roles: [],
    status: null,
    uid: null,
    uuid: '',
    fullname: '',
    hasBeenLoadInternal: false,
    isLoggingOut: false
  },
  getters: {},
  actions: {
    async validateSessionData () {
      try {
        const userData = JSON.parse(localStorage.getItem('user'))
        if (userData) {
          const response = await api.get({ url: process.env.VUE_APP_WS_BASE + '/get/user-session-status' })
          const ok = response.data.data
          if (!ok) {
            localStorage.removeItem('user')
            console.warn('User session timeout, logout')
          }
          return !!response.data
        } else {
          console.warn('User required, please navigate to login')
          return false
        }
      } catch (e) {
        console.error(e)
        return false
      }
    },
    resetState ({ commit, state, dispatch }, payload) {
      const original = {
        email: '',
        permission: [],
        roles: [],
        status: null, // type number
        uid: null, // type number
        uuid: ''
      }
      Object.keys(original).forEach((key, index) => {
        state[key] = original[key]
      })
    },
    async doLogin ({ commit, state, dispatch }, payload) {
      const formData = utils.payloadToFormData({
        user: payload.username,
        pass: payload.password
      })
      const url = process.env.VUE_APP_WS_BASE + '/get/login'
      return await dispatch('requestLoginByUrlAndPayload', {
        url: url,
        formData: formData
      })
    },
    async requestLoginByUrlAndPayload ({ commit, state, dispatch }, payload) {
      if (!payload.url || !payload.formData) {
        console.log('require [rul, formData] get', payload)
      }
      const response = await api.post({
        url: payload.url,
        data: payload.formData
      })
      const data = response.data
      if (data.code === 200) {
        const userData = data.data
        Object.keys(userData).forEach((key, index) => {
          /* This invoke vue getters */
          Vue.set(state, key, userData[key])
          /*
            This does not invoke vue getters
            state[key] = userData[key]
          */
        })
        await dispatch('saveStorage', userData)
        return true
      } else {
        console.warn(data)
        return false
      }
    },
    async saveStorage ({ commit, state, dispatch }, userData) {
      const userString = JSON.stringify(userData)
      localStorage.setItem('user', userString)
    },
    loadInternalStorage ({ commit, state, dispatch }, payload) {
      if (state.hasBeenLoadInternal) {
        return true
      }
      state.hasBeenLoadInternal = true
      const userData = JSON.parse(localStorage.getItem('user'))
      if (userData) {
        Object.keys(userData).forEach((key, index) => {
          state[key] = userData[key]
        })
        return true
      }
    },
    async doLogout ({ commit, state, dispatch }, payload) {
      const formData = utils.payloadToFormData({ uuid: state.uuid })
      const url = process.env.VUE_APP_WS_BASE + '/get/logout'
      state.isLoggingOut = true
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 200) {
        localStorage.removeItem('user')
        dispatch('resetState')
        state.isLoggingOut = false
        return true
      } else {
        state.isLoggingOut = false
        console.warn(data)
      }
    },
    async requestNewPassword ({ commit, state, dispatch, getters }, payload) {
      const url = process.env.VUE_APP_WS_BASE + '/get/request-reset-password?email=' + payload.email
      const response = await api.get({ url: url }).catch(() => { return false })
      const data = response.data
      return data?.code === 200 || false
    }
  },
  mutations: {}
}

export default user
